import React from 'react';
import PropTypes from 'prop-types';
import './BreadCrumbsLayout.scss';

const BreadCrumbs = props => {
  const {
    links: { links },
    history,
    selectedBrand,
    locationParams,
    children,
  } = props;

  const brandName = selectedBrand ? selectedBrand.name : '';

  return (
    <React.Fragment>
      <div className="breadcrumbs">
        {links.map((item, index) => (
          <div
            onClick={() => history.push(item.getPath(locationParams))}
            className="breadcrumbs__link"
            key={index}
          >
            {item.getName(brandName)}
          </div>
        ))}
      </div>
      <div className="breadcrumbs__content">{children}</div>
    </React.Fragment>
  );
};

BreadCrumbs.propTypes = {
  links: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedBrand: PropTypes.object,
  locationParams: PropTypes.object.isRequired,
};

export { BreadCrumbs };
