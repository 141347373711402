import React from 'react';

export const MarkerIcon = props => (
  <svg width="71" height="83" viewBox="0 0 71 83" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g opacity="0.4" filter="url(#filter0_f)">
      <path
        d="M41.1 76.0414C47.1841 75.5897 51.5 74.4543 51.5 73.125C51.5 71.3991 44.2246 70 35.25 70C26.2754 70 19 71.3991 19 73.125C19 74.4543 23.3159 75.5897 29.4 76.0414C32.0371 76.2007 33.95 76.6903 33.95 77.25C33.95 77.3881 34.532 77.5 35.25 77.5C35.968 77.5 36.55 77.3881 36.55 77.25C36.55 76.6903 38.4629 76.2007 41.1 76.0414Z"
        fill="#FF4C4F"
      />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M46.5 60.4138C58.2002 55.8974 66.5 44.543 66.5 31.25C66.5 13.9911 52.5089 0 35.25 0C17.9911 0 4 13.9911 4 31.25C4 44.543 12.2999 55.8974 24 60.4138C29.0714 62.0066 32.75 66.903 32.75 72.5C32.75 73.8807 33.8693 75 35.25 75C36.6307 75 37.75 73.8807 37.75 72.5C37.75 66.903 41.4286 62.0066 46.5 60.4138Z"
        fill="#FF4C4F"
      />
    </g>
    <circle cx="35.25" cy="31.25" r="12.5" fill="white" />
    <path d="M65 31.25C65 43.8863 57.1212 54.6861 46.0028 58.9978C40.3276 60.8036 36.25 66.2688 36.25 72.5C36.25 73.0523 35.8023 73.5 35.25 73.5C34.6977 73.5 34.25 73.0523 34.25 72.5C34.25 66.2688 30.1724 60.8036 24.4972 58.9978C13.3788 54.6861 5.5 43.8863 5.5 31.25C5.5 14.8195 18.8195 1.5 35.25 1.5C51.6805 1.5 65 14.8195 65 31.25Z" stroke="#FF4C4F" strokeWidth="3"/>
    <defs>
      <filter
        id="filter0_f"
        x="14"
        y="65"
        width="42.5"
        height="17.5"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur" />
      </filter>
      <filter
        id="filter1_d"
        x="0"
        y="0"
        width="70.5"
        height="83"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.695833 0 0 0 0 0 0 0 0 0 0.0271375 0 0 0 0.05 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
