import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { findOne } from '../helpers/arrayHelper';

export const PrivateRoute = props => {
  const { component: Component, allowedRoles, ...rest } = props;

  const userData = localStorage.getItem('userData');
  const userRoles = JSON.parse(userData).roles;
 
  return (
    <Route
      {...rest}
      render={routeProps => {
        if (findOne(allowedRoles, userRoles)) {
          return <Component {...routeProps} />;
        }
        return <Redirect to="/forbidden" />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  allowedRoles: PropTypes.array.isRequired,
  component: PropTypes.func.isRequired,
};
