import { NOTIFICATION_ACTION_TYPES } from './notificationConstants';
import { toast } from 'react-toastify';

export const notificationActions = {
  showSuccessNotification: message => {
    toast.success(message);
    return {
      type: NOTIFICATION_ACTION_TYPES.SHOW_SUCCESS_NOTIFICATION,
    };
  },
  showErrorNotification: message => {
    toast.error(message);
    return {
      type: NOTIFICATION_ACTION_TYPES.SHOW_ERROR_NOTIFICATION,
    };
  },
};
