import React from 'react';
import PropTypes from 'prop-types';
import { CustomTimePicker } from '../../TimePicker';
import './TimeRange.scss';

const TimeRange = props => {
  const { startTimeName, endTimeName } = props;
  return (
    <div className="time-range">
      <CustomTimePicker name={startTimeName} />
      <div className="time-range__to">to</div>
      <CustomTimePicker name={endTimeName} />
    </div>
  );
};

TimeRange.propTypes = {
  startTimeName: PropTypes.string.isRequired,
  endTimeName: PropTypes.string.isRequired,
};

export { TimeRange };
