export const BRAND_ACTION_TYPES = {
  GET_BRANDS: 'GET_BRANDS',
  GET_BRANDS_SUCCESS: 'GET_BRANDS_SUCCESS',
  CREATE_BRAND: 'CREATE_BRAND',
  ADD_PLACE: 'ADD_PLACE',
  SELECT_BRAND: 'SELECT_BRAND',
  GET_ALL_BRANDS: 'GET_ALL_BRANDS',
  GET_ALL_BRANDS_SUCCESS: 'GET_ALL_BRANDS_SUCCESS',
  GET_ALL_BRANDS_BY_SEARCH: 'GET_ALL_BRANDS_BY_SEARCH',
  GET_ALL_BRANDS_BY_SEARCH_SUCCESS: 'GET_ALL_BRANDS_BY_SEARCH_SUCCESS',
  UPDATE_LOADING_STATUS: 'UPDATE_LOADING_STATUS',
  GET_BRANDS_FROM_YELP: 'GET_BRANDS_FROM_YELP',
  GET_BRANDS_FROM_YELP_SUCCESS: 'GET_BRANDS_FROM_YELP_SUCCESS',
  ADD_BRAND_FROM_YELP: 'ADD_BRAND_FROM_YELP',
  CLEAR_ALL_BRANDS: 'CLEAR_ALL_BRANDS',
  GET_BRAND_PLACES: 'GET_BRAND_PLACES',
  GET_BRAND_PLACES_SUCCESS: 'GET_BRAND_PLACES_SUCCESS',
  GET_BRAND_OFFERS: 'GET_BRAND_OFFERS',
  GET_BRAND_OFFERS_SUCCESS: 'GET_BRAND_OFFERS_SUCCESS',
};
