import { all, takeLatest, put } from 'redux-saga/effects';
import { dataApi } from '../../../api/dataApi';
import { currenciesActions } from './currenciesActions';
import { CURRENCIES_ACTION_TYPES } from './currenciesConstants';

function* getCurrencies() {
  try {
    const response = yield dataApi.getCurrencies();

    if (response) {
      yield put(currenciesActions.getCurrenciesSuccess(response.data));
    }
  } catch (err) {
    console.error('get currencies error', err);
  }
}

function* sagas() {
  yield all([takeLatest(CURRENCIES_ACTION_TYPES.GET_CURRENCIES, getCurrencies)]);
}

export const currenciesSagas = sagas;
