import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector } from 'redux-form';
import { menuActions } from '../../redux/modules/menu/menuActions';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';
import { AddMenuItem } from './AddMenuItemPage';

const mapStateToProps = (state, ownProps) => {
  const {
    menu: { menuCategories },
    form,
  } = state;
  const { brandId } = ownProps.match.params;
  const selectedBrand = getBrandById(state, brandId);
  const formSelector = formValueSelector('addMenuItemForm');

  return {
    categories: menuCategories,
    selectedBrand,
    brandId,
    selectedCategoryId: formSelector(state, 'selectedCategoryId'),
    selectedSubCategoryId: formSelector(state, 'selectedSubCategoryId'),
    isVisible: formSelector(state, 'isVisible'),
    formObj: form['addMenuItemForm'] || { values: {} },
  };
};

const mapDispatchToProps = {
  getMenuCategories: menuActions.getMenuCategories,
  addMenuItem: menuActions.addMenuItem
};

const form = reduxForm({
  form: 'addMenuItemForm',
  initialValues: {
    selectedCategoryId: '',
    isVisible: true,
  },
  onSubmit: () => {},
})(AddMenuItem);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const AddMenuItemPage = compose(
  withRouter,
  withConnect,
)(form);
