import { all, put, takeLatest } from 'redux-saga/effects';
import { placeApi } from '../../../api/placeApi';
import { placeActions } from './placeActions';
import { PLACE_ACTION_TYPES } from './placeConstants';

function* getPlacesSaga() {
  try {
    const response = yield placeApi.getPlaces();
    if (response) {
      yield put(placeActions.getPlacesSuccess(response.data));
    }
  } catch (error) {
    console.error('getPlaces', error);
  }
}

function* sagas() {
  yield all([takeLatest(PLACE_ACTION_TYPES.GET_PLACES, getPlacesSaga)]);
}

export const placeSagas = sagas;
