import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Header } from './Header';

const mapStateToProps = state => {
  return {
    initialValues: {
      search: '',
    },
  };
};

const mapDispatchToProps = {
}

const form = reduxForm({
  form: 'offerListForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: () => {},
})(Header);

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
