import './Selector.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';

import { ArrowIcon } from '../Icons/ArrowIcon';

const InnerSelector = props => {
  const {
    input,
    customClass,
    labelBefore,
    labelAfter,
    emptyLabel,
    customChange,
    meta: { touched, error, warning },
  } = props;

  const options = props.options || [];

  const handleChange = e => {
    const target = e.target;
    const value = target.value;
    props.input.onChange(value);
    
    if(customChange) {
      customChange(value)
    }
  };

  return (
    <div className={classnames('selector', customClass)}>
      {labelBefore && <div className="selector__label-before">{labelBefore}</div>}
      <select className={classnames('selector__select', `${customClass}__select`)} {...input} onChange={e => handleChange(e)}>
      {emptyLabel && <option key="placeholder" value="" disabled hidden>{emptyLabel}</option>}
        {options.map(i => {
          return (
            <option {...input} key={i.value} value={i.value}>
              {i.label}
            </option>
          );
        })}
      </select>
      <ArrowIcon className="multiselect__arrow-icon" />
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      {labelAfter && <div className="selector__selector-after">{labelAfter}</div>}
    </div>
  );
};

export const Selector = props => <Field name={props.name} component={InnerSelector} {...props} />;

Selector.defaultProps = {
  labelBefore: null,
  labelAfter: null,
  emptyLabel: ''
};

Selector.propTypes = {
  onChange: PropTypes.func,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  emptyLabel: PropTypes.string,
  customChange: PropTypes.func
};

export default Selector;
