import './Button.scss';

import React from 'react';
import classnames from 'classnames';

export const Button = ({ children, customClass, ...otherProps }) => {
  return (
    <button className={classnames('btn-component', customClass)} {...otherProps}>
      {children}
    </button>
  );
};
