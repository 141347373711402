import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { SearchIcon } from '../Icons/SearchIcon';
import { FilterIcon } from '../Icons/FilterIcon';
import './Search.scss';

const InnerSearch = props => {
  const {
    input,
    customClass,
    customChange,
    value,
    placeholder,
    withFilter,
    type,
    meta: { touched, error, warning },
  } = props;

  const handleChange = e => {
    const { value } = e.target;
    input.onChange(value);
    customChange(value);
  };

  return (
    <div className={classnames('search', customClass)}>
      <div className="search__input-container">
        <SearchIcon className="search__search-icon" />
        <input
          className="search__input"
          {...input}
          type={type}
          placeholder={placeholder}
          onChange={e => handleChange(e)}
          value={value}
        />
      </div>
      {withFilter && <FilterIcon className="search__filter-icon" />}
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  );
};

export const Search = props => <Field name={props.name} component={InnerSearch} {...props} />;

Search.defaultProps = {
  value: '',
  placeholder: '',
  customChange: () => ({}),
  withFilter: false,
};

Search.propTypes = {
  customChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  withFilter: PropTypes.bool,
};

export default Search;
