import React, { useState, useEffect, useRef } from 'react';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import { MarkerIcon } from '../../components/common/Icons/MarkerIcon';
import classnames from 'classnames';
import './PlacesMapPage.scss';
import { Spinner } from '../../components/common/Spinner';
import { AddIcon } from '../../components/common/Icons/AddIcon';
import { MinusIcon } from '../../components/common/Icons/MinusIcon';

const PlacesMap = props => {
  const { getPlaces, places, fetching } = props;
  const isMobile = navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod/i);
  const mapRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);
  const [position, setPosition] = useState({ latitude: 40.73058, longitude: -73.935232 });
  const [viewport, setViewport] = useState({
    width: '100%',
    height: isMobile ? 'calc(100vh - 40px)' : 'calc(100vh - 60px)',
    flyTo: true,
  });
  const [zoom, setZoom] = useState(8);

  useEffect(() => {
    getPlaces();
    navigator.geolocation.getCurrentPosition(
      position => {
        setPosition({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      },
      () => {},
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
    );
  }, [getPlaces]);

  const changeViewport = viewport => {
    setZoom(viewport.zoom);
    setPosition({ latitude: viewport.latitude, longitude: viewport.longitude });
    setViewport(viewport);
  };

  const renderMarkers = () => {
    if (!places.length) {
      return null;
    }
    return places.map((place, index) => {
      return (
        <Marker key={index} latitude={place.latitude} longitude={place.longitude}>
          <MarkerIcon
            className={classnames(
              'places-map__marker-icon',
              activeMarkerIndex === index && 'places-map__active-marker-icon',
            )}
            onClick={() => {
              setActiveMarkerIndex(index);
              setPopupInfo({
                latitude: place.latitude,
                longitude: place.longitude,
                title: place.brandName,
                subTitle: place.addressToDisplay,
              });
            }}
          />
        </Marker>
      );
    });
  };

  const renderPopup = () => {
    if (popupInfo) {
      return (
        <Popup
          latitude={popupInfo.latitude}
          longitude={popupInfo.longitude}
          onClose={() => {
            setPopupInfo(null);
            setActiveMarkerIndex(null);
          }}
          dynamicPosition={false}
          className="places-map__popup"
        >
          <div className="places-map__popup-title">{popupInfo.title}</div>
          <div className="places-map__popup-subtitle">{popupInfo.subTitle}</div>
        </Popup>
      );
    } else {
      return null;
    }
  };

  if (fetching) {
    return <Spinner />;
  } else {
    return (
      <ReactMapGL
        {...viewport}
        {...position}
        zoom={zoom}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        className="places-map__map"
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onViewportChange={viewport => changeViewport(viewport)}
      >
        {renderMarkers()}
        {renderPopup()}
        {!isMobile && (
          <div className="places-map__control-buttons-container">
            <div className="places-map__zoom-buttons">
              <AddIcon onClick={() => setZoom(zoom + 1)} />
              <div className="places-map__divider"></div>
              <MinusIcon onClick={() => setZoom(zoom - 1)} />
            </div>
          </div>
        )}
      </ReactMapGL>
    );
  }
};

export { PlacesMap };
