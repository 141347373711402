import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../components/common/Button';
import { Checkbox } from '../../components/common/Checkbox';
import { Input } from '../../components/common/Input';
import { ImageUpload } from '../../components/common/ImageUpload';
import { MenuItemCategorySelector } from '../../components/common/MenuItemCategorySelector';
import { ArrowIcon } from '../../components/common/Icons/ArrowIcon';
import { getCurrencySymbol } from '../../helpers/currencyHelper';
import './AddMenuItemPage.scss';

const AddMenuItem = props => {
  const {
    categories,
    selectedCategoryId,
    history,
    getMenuCategories,
    selectedBrand,
    brandId,
    selectedSubCategoryId,
    formObj,
    addMenuItem,
    isVisible,
  } = props;
  const brandName = selectedBrand ? selectedBrand.name : '';
  const currencySymbol = getCurrencySymbol('USD');

  useEffect(() => {
    getMenuCategories(brandId);
  }, [getMenuCategories, brandId]);

  const handleSaveMenuItem = () => {
    const { title, price, description, image } = formObj.values;

    addMenuItem({
      name: title,
      price,
      description,
      menuSubcategoryId: selectedSubCategoryId,
      brandId,
      isVisible,
      imageUrl: image,
    });
  };

  return (
    <div className="add-menu-item">
      <div className="add-menu-item__title">{`Add menu for ${brandName}`}</div>
      <div className="add-menu-item__form">
        <MenuItemCategorySelector
          categories={categories}
          history={history}
          brandId={brandId}
          selectedCategoryId={selectedCategoryId}
        />
        <div className="add-menu-item__item-information">
          <div className="add-menu-item__item-information-title-container">
            <div className="add-menu-item__item-information-title">Item information</div>
            <ArrowIcon />
          </div>
          <Input name="title" labelBefore="Item title" customClass="add-menu-item__input" />
          <Input
            name="description"
            labelBefore="Item description"
            customClass="add-menu-item__multiline-input"
            multiline={true}
          />
          <ImageUpload
            name="image"
            description="Upload a photo of the item for visitors to understand what they’re buying."
          />
        </div>
        <div>
          <Checkbox
            name="isVisible"
            index={brandId}
            label="Menu item will be visible in the menu"
            customClass="add-menu-item__visibility-checkbox"
          />
        </div>
        <div className="add-menu-item__pricing-details">
          <div className="add-menu-item__pricing-details-title-container">
            <div className="add-menu-item__pricing-details-title">Pricing details</div>
            <ArrowIcon />
          </div>
          <Input
            labelBefore="Item price"
            name="price"
            symbolBeforeValue={currencySymbol}
            customClass="add-menu-item__input-with-symbol"
            withClearing
          />
        </div>
        <div className="add-menu-item__buttons-container">
          <Button customClass="add-menu-item__button">Save and add another</Button>
          <Button customClass="add-menu-item__button--save" onClick={() => handleSaveMenuItem()}>
            Save menu
          </Button>
        </div>
      </div>
    </div>
  );
};

AddMenuItem.defaultProps = {
  categories: [],
  selectedCategoryId: '',
  selectedSubCategoryId: '',
};

AddMenuItem.propTypes = {
  categories: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  selectedBrand: PropTypes.object,
  brandId: PropTypes.string.isRequired,
  selectedCategoryId: PropTypes.string.isRequired,
  getMenuCategories: PropTypes.func.isRequired,
  selectedSubCategoryId: PropTypes.string.isRequired,
  formObj: PropTypes.object.isRequired,
  addMenuItem: PropTypes.func.isRequired,
};

export { AddMenuItem };
