import './SignUpComponent.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import { required } from '../../helpers/formValidation';

export const SignUpComponent = props => {
  const { formObj, handleSubmit, signUp, invalid, submitting } = props;

  return (
    <div className="sign-up">
      <form className="sign-up__form" onSubmit={handleSubmit}>
        <div className="sign-up__header">Sign Up</div>
        <Input customClass="sign-up__input" validate={required} name="email" labelBefore="Email" />
        <Input
          type="password"
          validate={required}
          customClass="sign-up__input"
          name="password"
          labelBefore="Password"
        />

        <Button
          customClass="sign-up__submit-button"
          disabled={invalid || submitting}
          onClick={() => {
            signUp(formObj.values);
          }}
        >
          Sign Up
        </Button>
      </form>
    </div>
  );
};

SignUpComponent.propTypes = {
  formObj: PropTypes.object.isRequired,
  signUp: PropTypes.func.isRequired,
};
