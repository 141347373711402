import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { AddBrandFromYelp } from './AddBrandFromYelpPage';
import { brandActions } from '../../redux/modules/brand/brandActions';

const mapStateToProps = state => {
  const formSelector = formValueSelector('addBrandFromYelpForm');
  const {
    brand: { brandsFromYelp, fetchingBrandsFromYelp },
  } = state;

  return {
    brandName: formSelector(state, 'brandName'),
    brandsFromYelp,
    fetching: fetchingBrandsFromYelp
  };
};

const mapDispatchToProps = {
  getBrandsFromYelp: brandActions.getBrandsFromYelp,
  addBrandFromYelp: brandActions.addBrandFromYelp
};

const form = reduxForm({
  form: 'addBrandFromYelpForm',
})(AddBrandFromYelp);

const AddBrandFromYelpPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);

export { AddBrandFromYelpPage };
