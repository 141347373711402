import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { BreadCrumbs } from './BreadCrumbsLayout';
import { getLinks } from '../../redux/modules/history/historySelector';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';

const mapStateToProps = (state, ownProps) => {
  const links = getLinks(state);
  const { params } = ownProps.match;
  const { brandId } = params;
  const selectedBrand = getBrandById(state, brandId);

  return {
    links,
    selectedBrand,
    locationParams: params
  };
};

const withConnect = connect(
  mapStateToProps,
  null,
);

export const BreadCrumbsLayout = compose(
  withRouter,
  withConnect,
)(BreadCrumbs);
