import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { menuActions } from '../../redux/modules/menu/menuActions';
import { getSubCategoriesByCategory } from '../../redux/modules/menu/menuSelectors';
import { Menu } from './MenuPage';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';

const mapStateToProps = (state, ownProps) => {
  const formSelector = formValueSelector('menuForm');
  const {
    menu: { fetching, menu: { categories }, selectedMenuList },
  } = state;
  const selectedCategoryName = formSelector(state, 'selectedCategoryName');
  const subCategories = getSubCategoriesByCategory(state, { category: selectedCategoryName });
  const { brandId } = ownProps.match.params;
  const selectedBrand = getBrandById(state, brandId);

  return {
    fetching,
    categories,
    subCategories,
    selectedMenuList,
    selectedCategoryName: formSelector(state, 'selectedCategoryName'),
    selectedBrand,
    brandId,
  };
};

const mapDispathToProps = {
  getMenuList: menuActions.getMenuList,
  updateSelectedMenuList: category => menuActions.updateSelectedMenuList(category),
  setInitialCategory: category => change('menuForm', 'selectedCategoryName', category),
  removeMenuItem: menuActions.removeMenuItem
};

const form = reduxForm({
  form: 'menuForm',
  initialValues: {
    selectedCategoryName: '',
  },
  onSubmit: () => {},
})(Menu);

const withConnect = connect(
  mapStateToProps,
  mapDispathToProps,
);

const MenuPage = compose(
  withRouter,
  withConnect,
)(form);

export { MenuPage };
