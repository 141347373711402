import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector } from 'redux-form';
import { offerActions } from '../../../redux/modules/offer/offerActions';
import { DiscountForm } from './DiscountForm';

const mapStateToProps = state => {
  const { form } = state;
  const formSelector = formValueSelector('addOfferForm');

  return {
    formObj: form.discountOfferForm || { values: {} },
    title: formSelector(state, 'title'),
    placesIds: formSelector(state, 'placesIds'),
    imageUrl: formSelector(state, 'image'),
  };
};

const mapDispatchToProps = dispatch => ({
  createDiscount: (title, placesIds, imageUrl, values, getOfferId) => {
    const { startDate, endDate, excludedDaysOfWeek, excludedCustomDays, discount } = values;

    dispatch(
      offerActions.createDiscount({
        offerName: title,
        discount,
        whenPublic: {
          startDate,
          endDate,
          excludedDates: [...excludedDaysOfWeek, ...excludedCustomDays],
        },
        placesIds,
        getOfferId,
        imageUrl,
      }),
    );
  },
});

const form = reduxForm({
  form: 'discountOfferForm',
  destroyOnUnmount: false,
  initialValues: {
    excludedDaysOfWeek: [],
    excludedCustomDays: [],
    discount: 0,
  },
  onSubmit: () => {},
})(DiscountForm);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const DiscountFormContainer = compose(
  withRouter,
  withConnect,
)(form);
