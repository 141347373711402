import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { SignUpComponent } from './SignUpComponent';
import { authActions } from '../../redux/modules/auth/authActions';

const mapStateToProps = state => {
  const { form } = state;

  return {
    formObj: form.signUpForm || { values: {} },
  };
};

const mapDispatchToProps = {
  signUp: authActions.signUp,
};

const form = reduxForm({
  form: 'signUpForm',
  destroyOnUnmount: true,
  onSubmit: () => {},
})(SignUpComponent);

export const SignUpPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
