import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { offerActions } from '../../../redux/modules/offer/offerActions';
import { menuActions } from '../../../redux/modules/menu/menuActions';
import {
  getAllMenuItems,
  getSubCategoriesByCategory,
} from '../../../redux/modules/menu/menuSelectors';
import { SetForm } from './SetForm';

const mapStateToProps = (state, ownProps) => {
  const {
    form,
    menu: {
      selectedMenuList,
      menu: { categories },
      fetching,
    },
  } = state;
  const { brandId } = ownProps;
  const formSelector = formValueSelector('setOfferForm');
  const offerFormSelector = formValueSelector('addOfferForm');
  const selectedCategoryName = formSelector(state, 'selectedCategoryName');
  const subCategories = getSubCategoriesByCategory(state, { category: selectedCategoryName });
  const allMenuItems = getAllMenuItems(state);

  return {
    formObj: form.setOfferForm || { values: {} },
    title: offerFormSelector(state, 'title'),
    placesIds: offerFormSelector(state, 'placesIds'),
    imageUrl: offerFormSelector(state, 'image'),
    brandId,
    selectedMenuList,
    selectedMenuItemsIds: formSelector(state, 'menuItemsIds'),
    selectedCategoryName,
    categories,
    subCategories,
    allMenuItems,
    fetching,
  };
};

const mapDispatchToProps = dispatch => ({
  createSet: (title, placesIds, imageUrl, values, menuItemsIds, getOfferId) => {
    const { startDate, endDate, excludedDaysOfWeek, excludedCustomDays, price } = values;

    dispatch(
      offerActions.createSet({
        menuItemsIds,
        offerName: title,
        discountedPrice: price,
        whenPublic: {
          startDate,
          endDate,
          excludedDates: [...excludedDaysOfWeek, ...excludedCustomDays],
        },
        placesIds,
        getOfferId,
        imageUrl,
      }),
    );
  },
  getMenuList: brandId => dispatch(menuActions.getMenuList(brandId)),
  updateSelectedMenuList: category => dispatch(menuActions.updateSelectedMenuList(category)),
  setInitialCategory: category =>
    dispatch(change('setOfferForm', 'selectedCategoryName', category)),
});

const form = reduxForm({
  form: 'setOfferForm',
  initialValues: {
    excludedDaysOfWeek: [],
    excludedCustomDays: [],
    menuItemsIds: [],
    selectedCategoryName: '',
  },
  onSubmit: () => {},
})(SetForm);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const SetFormContainer = compose(
  withRouter,
  withConnect,
)(form);
