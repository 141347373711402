import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { ArrowIcon } from '../Icons/ArrowIcon';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import './TimePicker.scss';
import moment from 'moment';

const InnerTimePicker = props => {
  const {
    input: { value, ...restInput },
    meta: { touched, error, warning },
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [formatedValue, setFormatedValue] = useState(value ? value.format('LT') : '');

  useEffect(() => {
    if (!value) {
      setFormatedValue('');
    } else {
      setFormatedValue(value.format('LT'));
    }
  }, [value]);

  const handleChange = value => {
    if (value) {
      setFormatedValue(value.format('LT'));
      restInput.onChange(value);
    } else {
      restInput.onChange(null);
    }
  };
  
  return (
    <div className="time-picker">
      <div className="time-picker__input-container" onClick={() => setIsOpen(true)}>
        <TimePicker
          {...restInput}
          className="time-picker__input"
          allowEmpty={false}
          showSecond={false}
          use12Hours={true}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onChange={handleChange}
          defaultOpenValue={value ? value : moment()}
        />
        <div className="time-picker__input-value">{formatedValue}</div>
        <ArrowIcon className="time-picker__icon" onClick={() => setIsOpen(true)} />
      </div>
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
    </div>
  );
};

const CustomTimePicker = props => (
  <Field name={props.name} component={InnerTimePicker} {...props} />
);

CustomTimePicker.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
};

export { CustomTimePicker };
