import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { offerActions } from '../../../redux/modules/offer/offerActions';
import { menuActions } from '../../../redux/modules/menu/menuActions';
import {
  getAllMenuItems,
  getSubCategoriesByCategory,
} from '../../../redux/modules/menu/menuSelectors';
import { HappyHourForm } from './HappyHourForm';
import { getDaysWithTimeOffsetIntervals } from '../../../helpers/dayHelper';

const mapStateToProps = (state, ownProps) => {
  const {
    form,
    menu: {
      selectedMenuList,
      menu: { categories },
      fetching,
    },
  } = state;
  const { brandId } = ownProps;
  const formSelector = formValueSelector('happyHourForm');
  const offerFormSelector = formValueSelector('addOfferForm');
  const allMenuItems = getAllMenuItems(state);
  const selectedCategoryName = formSelector(state, 'selectedCategoryName');
  const subCategories = getSubCategoriesByCategory(state, { category: selectedCategoryName });

  return {
    formObj: form.happyHourForm || { values: {} },
    title: offerFormSelector(state, 'title'),
    description: offerFormSelector(state, 'description'),
    placesIds: offerFormSelector(state, 'placesIds'),
    imageUrl: offerFormSelector(state, 'image'),
    brandId,
    selectedMenuList,
    selectedMenuItemsIds: formSelector(state, 'menuItemsIds'),
    discountedPrices: formSelector(state, 'discountedPrices'),
    daysWithTimeIntervals: formSelector(state, 'daysWithTimeIntervals'),
    selectedCategoryName,
    categories,
    subCategories,
    fetching,
    allMenuItems,
  };
};

const mapDispatchToProps = dispatch => ({
  createHappyHour: (title, description, placesIds, imageUrl, values) => {
    const {
      startDate,
      endDate,
      excludedDaysOfWeek,
      excludedCustomDays,
      discountedPrices,
      daysWithTimeIntervals,
    } = values;
    const daysWithTimeOffsetIntervals = getDaysWithTimeOffsetIntervals(daysWithTimeIntervals);

    dispatch(
      offerActions.createHappyHour({
        offerName: title,
        menuItems: discountedPrices,
        whenPublic: {
          startDate,
          endDate,
          excludedDates: [...excludedDaysOfWeek, ...excludedCustomDays],
        },
        offerDays: daysWithTimeOffsetIntervals,
        placesIds,
        description,
        imageUrl,
      }),
    );
  },
  getMenuList: brandId => dispatch(menuActions.getMenuList(brandId)),
  updateSelectedMenuList: category => dispatch(menuActions.updateSelectedMenuList(category)),
  setDiscountedPrices: discountedPrices =>
    dispatch(change('happyHourForm', 'discountedPrices', discountedPrices)),
  setInitialCategory: category =>
    dispatch(change('happyHourForm', 'selectedCategoryName', category)),
});

const form = reduxForm({
  form: 'happyHourForm',
  initialValues: {
    excludedDaysOfWeek: [],
    excludedCustomDays: [],
    menuItemsIds: [],
    discountedPrices: [],
    selectedCategoryName: '',
    daysWithTimeIntervals: [],
  },
  onSubmit: () => {},
})(HappyHourForm);

export const HappyHourFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
