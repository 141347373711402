import './ImageUpload.scss';

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { imageApi } from '../../../api/imageApi';
import { UploadPhotoIcon } from '../Icons/UploadPhotoIcon';

const InnerImageUpload = props => {
  const { customClass, description, input } = props;
  const [imgUrl, setImgUrl] = useState(input.value ? input.value : '');
  const inputEl = useRef(null);

  useEffect(() => {
    if (input.value) {
      setImgUrl(input.value);
    }
  }, [input.value]);

  const handleChange = async e => {
    const value = e.target.files[0];
    const data = new FormData();
    data.append('image', value);
    const res = await imageApi.getImgUrl(data);

    if (res.data) {
      setImgUrl(res.data);
      props.input.onChange(res.data);
    } else {
      props.input.onChange('');
    }
  };

  return (
    <div className={classnames('image-upload', customClass)}>
      <input
        ref={inputEl}
        className="image-upload__input"
        type="file"
        id="file"
        onChange={handleChange}
      />
      {imgUrl && (
        <img
          src={imgUrl}
          className="image-upload__image"
          onClick={() => inputEl.current.click()}
          alt=""
        />
      )}
      {!imgUrl && (
        <React.Fragment>
          <label htmlFor="file">
            <UploadPhotoIcon className="image-upload__icon" />
          </label>

          <div className="image-upload__description">
            <div className="image-upload__description-title">Upload a photo</div>
            <div className="image-upload__description-main">
              {description
                ? description
                : 'Upload a photo of your restaurant in order to gain more attention.'}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export const ImageUpload = props => (
  <Field name={props.name} component={InnerImageUpload} {...props} />
);

ImageUpload.defaultProps = {
  value: {},
};

ImageUpload.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  description: PropTypes.string,
  customClass: PropTypes.string,
};

export default ImageUpload;
