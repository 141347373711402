import React from 'react';
import ReactDOM from 'react-dom';
import { CloseIcon } from '../Icons/CloseIcon';
import './Modal.scss';

const Modal = props => {
  const { isModalOpen, setIsModalOpen, children, title } = props;

  const renderModal = () => {
    return (
      <div className="modal">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__title">{title}</div>
            <CloseIcon onClick={() => setIsModalOpen(false)} className="modal__close-icon" />
          </div>
          <div className="modal__body">{children}</div>
        </div>
      </div>
    );
  };

  if (!isModalOpen) {
    return null;
  } else {
    return ReactDOM.createPortal(renderModal(), document.body);
  }
};

Modal.defaultProps = {
  isModalOpen: false,
  title: 'Title',
};

export { Modal };
