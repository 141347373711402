import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getCurrencySymbol } from '../../../helpers/currencyHelper';
import { ThreeDotsIcon } from '../../../components/common/Icons/ThreeDotsIcon';
import { Button } from '../../../components/common/Button';
import { Modal } from '../../../components/common/Modal';
import { UrlFactory } from '../../../helpers/urlFactory';
import './MenuItemsList.scss';

const MenuItemsList = props => {
  const { menuItems, selectedCategoryName, history, brandId, removeMenuItem } = props;
  const [selectedMenuItemIndex, setSelectedMenuItemIndex] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeMenuItemId, setRemoveMenuItemId] = useState(null);
  const currencySymbol = getCurrencySymbol('USD');

  return (
    <div className="menu-items">
      <div className="menu-items__title">{selectedCategoryName}</div>
      <div className="menu-items__list">
        {menuItems.map((item, index) => {
          const isSelected = selectedMenuItemIndex === index;

          return (
            <div className="menu-items__item" key={index}>
              <img src={item.imageUrl} className="menu-items__item-image" alt="" />
              <div className="menu-items__item-body">
                <div>
                  <div className="menu-items__item-header">
                    <div className="menu-items__item-title-container">
                      <div className="menu-items__item-title">{item.name}</div>
                      <div className="menu-items__item-category">{item.subcategory}</div>
                    </div>
                    <div className="menu-items__item-price">
                      {currencySymbol}
                      {item.price}
                    </div>
                  </div>
                  <div className="menu-items__item-description">{item.description}</div>
                  {!item.isVisible && <div className="menu-items__item-hidden">Hidden</div>}
                </div>
                <div className="menu-items__item-buttons-container">
                  <div
                    className={classnames(
                      'menu-items__action-buttons-container',
                      isSelected && 'menu-items__action-buttons-container-active',
                    )}
                  >
                    <div
                      className="menu-items__item-delete"
                      onClick={() => {
                        setRemoveMenuItemId(item.id);
                        setIsModalOpen(true);
                      }}
                    >
                      Delete
                    </div>
                    <div
                      className="menu-items__item-edit"
                      onClick={() =>
                        isSelected &&
                        history.push({
                          pathname: UrlFactory.getEditMenuItemUrl(brandId),
                          state: {
                            menuItemId: item.id,
                            categoryName: selectedCategoryName,
                            subcategoryName: item.subcategory,
                            title: item.name,
                            description: item.description,
                            price: item.price,
                            imageUrl: item.imageUrl,
                            isVisible: item.isVisible,
                          },
                        })
                      }
                    >
                      Edit
                    </div>
                  </div>
                  <div
                    className={classnames(
                      'menu-items__icon-container',
                      isSelected && 'menu-items__icon-container-active',
                    )}
                    onClick={() => setSelectedMenuItemIndex(isSelected ? '' : index)}
                  >
                    <ThreeDotsIcon
                      className={isSelected ? 'menu-items__icon-active' : 'menu-items__icon'}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal title="Menu item delete" isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <div className="menu-items__remove-offer-modal">
          <div>Are you sure you want to delete this menu item?</div>
          <div className="menu-items__remove-offer-modal-buttons">
            <Button
              customClass="menu-items__remove-offer-modal-remove-button"
              onClick={() => {
                removeMenuItem(removeMenuItemId, brandId);
                setIsModalOpen(false);
              }}
            >
              Delete
            </Button>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

MenuItemsList.propTypes = {
  menuItems: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
};

export { MenuItemsList };
