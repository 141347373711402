import React from 'react';
import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import { Spinner } from '../../components/common/Spinner';
import { BrandCard } from './BrandCard';
import './AddBrandFromYelpPage.scss';

const AddBrandFromYelp = props => {
  const { getBrandsFromYelp, brandName, brandsFromYelp, fetching, addBrandFromYelp } = props;

  return (
    <div className="add-brand-from-yelp">
      <div className="add-brand-from-yelp__search-container">
        <Input name="brandName" labelBefore="Brand name" />
        <Button
          customClass="add-brand-from-yelp__search-button"
          onClick={() => getBrandsFromYelp(brandName)}
        >
          Search
        </Button>
      </div>
      <div className="add-brand-from-yelp__brands">
        {fetching ? (
          <Spinner />
        ) : (
          brandsFromYelp.map((brand, index) => <BrandCard brand={brand} key={index} addBrandFromYelp={addBrandFromYelp} />)
        )}
      </div>
    </div>
  );
};

export { AddBrandFromYelp };
