import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../components/common/Input';
import { Selector } from '../../components/common/Selector';
import { Multiselect } from '../../components/common/Multiselect';
import { TextArea } from '../../components/common/TextArea';
import { ArrowIcon } from '../../components/common/Icons/ArrowIcon';
import { Button } from '../../components/common/Button';
import { ImageUpload } from '../../components/common/ImageUpload';
import { CloseIcon } from '../../components/common/Icons/CloseIcon';
import { required, arrayOfValuesNotEmpty } from '../../helpers/formValidation';
import { UrlFactory } from '../../helpers/urlFactory';
import './AddBrandPage.scss';

const AddBrandPageForm = props => {
  const {
    formObj,
    cuisines,
    currencies,
    handleSubmit,
    createBrand,
    invalid,
    submitting,
    history,
    getCuisines,
    getCurrencies,
  } = props;

  useEffect(() => {
    getCuisines();
    getCurrencies();
  }, [getCuisines, getCurrencies]);

  return (
    <form className="add-brand" onSubmit={handleSubmit}>
      <div className="add-brand__header">
        Add brand
        <Button
          customClass="add-brand__import-button"
          onClick={() => history.push(UrlFactory.getAddBrandFromYelpUrl())}
        >
          Import from yelp
        </Button>
      </div>
      <div className="add-brand__form">
        <div className="add-brand__basic-info-container">
          <div className="add-brand__container-header">
            <div className="add-brand__title">Basic information </div>
            <ArrowIcon />
          </div>

          <Input
            customClass="add-brand__input"
            name="name"
            validate={required}
            labelBefore="Name"
            withClearing={true}
          />
          <TextArea
            customClass="add-brand__description-input"
            name="description"
            labelBefore="Description"
          />

          <Multiselect
            customClass="add-brand__cuisines-selector"
            name="cuisines"
            placeholder="Please select cuisine"
            labelBefore="Сuisines (up to 3)"
            validate={arrayOfValuesNotEmpty}
            isValid={arrayOfValuesNotEmpty}
            maxCount={3}
            options={cuisines.map(cuisine => {
              return {
                value: cuisine.id,
                label: cuisine.name,
              };
            })}
          />

          <ImageUpload customClass="add-brand__upload-file" name="image" />
        </div>

        <div className="add-brand__contact-info-container">
          <div className="add-brand__container-header">
            <div className="add-brand__title">Contact information</div>
            <ArrowIcon />
          </div>

          <Input customClass="add-brand__input" name="phone" labelBefore="Phone number" />
          <Input customClass="add-brand__input" name="email" labelBefore="Email" />
        </div>

        <div className="add-brand__service-info-container">
          <div className="add-brand__container-header">
            <div className="add-brand__title">Service information </div>
            <ArrowIcon />
          </div>

          <Selector
            customClass="add-brand__currency-selector"
            name="currency"
            validate={required}
            labelBefore="Currency"
            options={currencies.map(currency => {
              return {
                value: currency.id,
                label: currency.fullName,
              };
            })}
          />

          <Input
            customClass="add-brand__input"
            name="managerId"
            validate={required}
            labelBefore="Manager ID"
            withClearing={true}
          />

          <Button customClass="add-brand__invite-button">
            <div className="add-brand__close-icon-container">
              <CloseIcon className="add-brand__close-icon" />
            </div>
            Invite with email
          </Button>
        </div>

        <div className="add-brand__submit-button-container">
          <Button
            customClass="add-brand__submit-button"
            disabled={invalid || submitting}
            onClick={() => createBrand(formObj.values)}
          >
            Add brand
          </Button>
        </div>
      </div>
    </form>
  );
};

AddBrandPageForm.propTypes = {
  formObj: PropTypes.object.isRequired,
  cuisines: PropTypes.array.isRequired,
  currencies: PropTypes.array.isRequired,
  createBrand: PropTypes.func.isRequired,
  getCuisines: PropTypes.func.isRequired,
  getCurrencies: PropTypes.func.isRequired,
};

export { AddBrandPageForm };
