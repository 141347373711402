import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { DatePicker } from '../../../components/common/DatePicker';
import { Multiselect } from '../../../components/common/Multiselect';
import { AddIcon } from '../../../components/common/Icons/AddIcon';
import { Button } from '../../../components/common/Button';
import { ArrowIcon } from '../../../components/common/Icons/ArrowIcon';
import './Availability.scss';

const renderExcludedDays = ({ fields: days }) => {
  return (
    <React.Fragment>
      <div
        className="availability__add-custom-exclusion-div"
        onClick={() => {
          days.push()
        }}
      >
        Exclude custom day
      </div>
      {days.map((day, index) => {
        return (
          <div key={index} className="availability__exclude-custom-day-container">
            <div className="availability__exclude-custom-day-title">Select custom exclusions</div>
            <div className="availability__exclude-custom-day-with-button">
              <DatePicker name={day} customClass="availability__date-picker-custom" />
              <Button customClass="availability__clear-date-btn" onClick={() => days.remove(index)}>
                Clear
              </Button>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

const Availability = props => {
  const [exclusionFormView, setExclusionFormView] = useState(false);
  const {
    clearDates,
    startDate,
    endDate,
    excludedDays,
    setExcludedDaysOfWeek,
    excludedCustomDays,
    formName,
    title,
  } = props;

  const DAYS_OF_WEEK = [
    { value: '1', label: 'Mondays', index: 1 },
    { value: '2', label: 'Tuesdays', index: 2 },
    { value: '3', label: 'Wednesdays', index: 3 },
    { value: '4', label: 'Thursdays', index: 4 },
    { value: '5', label: 'Fridays', index: 5 },
    { value: '6', label: 'Saturdays', index: 6 },
    { value: '0', label: 'Sundays', index: 0 },
  ];

  const getDaysBetweenDates = (start, end, day) => {
    const result = [];

    let current = new Date(start);

    current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));

    while (current < end) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
    return result;
  };

  useEffect(() => {
    if (startDate && endDate) {
      let excludedDaysOfWeek = [];
      const promises = excludedDays.map(day => {
        const days = getDaysBetweenDates(startDate, endDate, Number(day));
        excludedDaysOfWeek = [...excludedDaysOfWeek, ...days];

        return Promise.resolve();
      });

      Promise.all(promises).then(() => {
        setExcludedDaysOfWeek(excludedDaysOfWeek, formName);
      });
    }
  }, [excludedDays, startDate, endDate, setExcludedDaysOfWeek, formName]);

  useEffect(() => {
    if (excludedCustomDays.length > 0) {
      setExclusionFormView(true);
    }
  }, [excludedCustomDays.length]);

  return (
    <div className="availability">
      <div className="availability__container-header">
        <div className="availability__title">Availability </div>
        <ArrowIcon />
      </div>

      <div className="availability__offer-public">
        <div className="availability__offer-public-title">{title}</div>
        <div className="availability__pick_date_area">
          <div className="availability__date-pickers-container">
            <DatePicker name="startDate" customClass="availability__date-picker" labelAfter="to" />
            <DatePicker name="endDate" customClass="availability__date-picker" />
          </div>

          <Button customClass="availability__clear-date-btn" onClick={() => clearDates(formName)}>
            Clear
          </Button>
        </div>
        {!exclusionFormView && (
          <Button
            customClass="availability__add-exclusions_btn"
            onClick={() => setExclusionFormView(true)}
          >
            <AddIcon className="availability__close-icon" />
            Add exclusions
          </Button>
        )}
        {exclusionFormView && (
          <div className="availability__add-exclusions_area">
            <Multiselect
              customClass="availability__multiselect"
              name="excludedDays"
              labelBefore="Exclusions"
              isValid={() => {}}
              options={DAYS_OF_WEEK}
              maxCount={6}
            />
            <FieldArray name="excludedCustomDays" component={renderExcludedDays} />
          </div>
        )}
      </div>
    </div>
  );
};

Availability.defaultProps = {
  excludedDays: [],
  exludeArray: [],
  exclusionDaysOfWeek: [],
  excludedCustomDays: [],
  title: 'Offer public',
};

Availability.propTypes = {
  clearDates: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  excludedDays: PropTypes.array.isRequired,
  setExcludedDaysOfWeek: PropTypes.func.isRequired,
  excludedCustomDays: PropTypes.array.isRequired,
  formName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export { Availability };
