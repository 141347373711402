import React from 'react';

import './Spinner.scss';

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner__part1"></div>
      <div className="spinner__part2"></div>
      <div className="spinner__part3"></div>
      <div className="spinner__part4"></div>
    </div>
  );
};

export { Spinner };
