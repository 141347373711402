import axios from 'axios';

import { API_URL } from './baseApi';

export const menuApi = {
  getMenuList: async brandId => {
    return await axios.get(`${API_URL}/biz/brands/${brandId}/menu`);
  },
  getMenuCategories: async brandId => {
    return await axios.get(`${API_URL}/biz/menu/${brandId}/categories`);
  },
  updateMenuCategories: async (brandId, categories) => {
    return await axios.put(`${API_URL}/biz/menu/${brandId}/categories`, {
      categories,
    });
  },
  addMenuItem: async payload => {
    return await axios.post(`${API_URL}/biz/menu`, {
      ...payload,
    });
  },
  updateMenuItem: async payload => {
    const { menuItemId, ...rest } = payload;
    return await axios.put(`${API_URL}/biz/menu/${menuItemId}`, {
      ...rest,
    });
  },
  removeMenuItem: async menuItemId => {
    return await axios.delete(`${API_URL}/biz/menu/${menuItemId}`);
  },
};
