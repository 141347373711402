import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { AUTH_ACTION_TYPES } from './authConstants';
import { authActions } from './authActions';
import { authApi } from '../../../api/authApi';
import { getAuthorizedUserRedirectUrl } from '../../../helpers/userRolesHelper';
import { notificationActions } from '../notification/notificationActions';
import { NOTIFICATION_MESSAGES } from '../notification/notificationConstants';
import { overlayActions } from '../overlay/overlayActions';

function* checkAuthStatusSaga() {
  try {
    const savedToken = localStorage.getItem('authToken');
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (savedToken && userData) {
      yield put(authActions.setAuthData(savedToken, userData));
    }
  } catch (err) {
    console.error('checkAuthStatusSaga error', err);
  }
}

function* loginSaga({ payload: { email, password } }) {
  yield put(overlayActions.showOverlay());
  try {
    const response = yield authApi.login(email, password);
    const userRoles = response.data.currentUser.roles;

    yield put(authActions.authSuccess(response.data.currentUser));
    yield call(checkAuthStatusSaga);
    yield put(push(getAuthorizedUserRedirectUrl(userRoles)));
  } catch (err) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('authSaga error', err);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* signUpSaga({ payload: { email, password } }) {
  yield put(overlayActions.showOverlay());
  try {
    const response = yield authApi.signUp(email, password);

    yield put(authActions.authSuccess(response.data.currentUser));
    yield call(checkAuthStatusSaga);
  } catch (err) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('signUpSaga error', err);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* logoutSaga() {
  localStorage.setItem('authToken', '');
  yield put(push('/'));
  yield put(authActions.clearAuthData());
}

function* sagas() {
  yield all([
    call(checkAuthStatusSaga),
    takeLatest(AUTH_ACTION_TYPES.LOGIN, loginSaga),
    takeLatest(AUTH_ACTION_TYPES.SIGN_UP, signUpSaga),
    takeLatest(AUTH_ACTION_TYPES.LOGOUT, logoutSaga),
  ]);
}

export const authSagas = sagas;
