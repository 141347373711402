import { MENU_ACTION_TYPES, SUB_CATEGORIES } from './menuConstants';

const defaultState = {
  menu: {
    menuByCategories: [],
    categories: [],
  },
  menuCategories: [],
  selectedMenuList: {
    items: [],
  },
  fetching: true,
  menuCategoriesFetching: true,
};

export const menuReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case MENU_ACTION_TYPES.GET_MENU_LIST:
      return { ...state, fetching: true };
    case MENU_ACTION_TYPES.GET_MENU_LIST_SUCCESS:
      return handleReceiveMenuList(state, payload);
    case MENU_ACTION_TYPES.UPDATE_SELECTED_MENU_LIST:
      return handleUpdateSelectedMenuList(state, payload);
    case MENU_ACTION_TYPES.CLEAR_SELECTED_MENU_LIST:
      return handleClearSelectedMenuList(state);
    case MENU_ACTION_TYPES.GET_MENU_CATEGORIES:
      return { ...state, menuCategoriesFetching: true };
    case MENU_ACTION_TYPES.GET_MENU_CATEGORIES_SUCCESS:
      return { ...state, menuCategories: payload, menuCategoriesFetching: false };
    case MENU_ACTION_TYPES.UPDATE_MENU_CATEGORIES_SUCCESS:
      return { ...state, menuCategories: payload };
    default:
      return state;
  }
};

const handleClearSelectedMenuList = state => {
  const { menuByCategories } = state.menu;
  const allItemsFromFirstCategory =
    menuByCategories.length !== 0 ? menuByCategories[0].allCategoryItems : [];
  return {
    ...state,
    selectedMenuList: {
      items: allItemsFromFirstCategory,
    },
  };
};

const handleUpdateSelectedMenuList = (state, payload) => {
  const { categoryName, subCategoryName } = payload;
  const category = state.menu.menuByCategories.find(item => item.categoryName === categoryName);
  let menuList = [];

  if (subCategoryName && subCategoryName !== SUB_CATEGORIES.ALL_ITEMS) {
    menuList = category.subCategories.find(item => item.name === subCategoryName).items;
  } else {
    menuList = category.allCategoryItems;
  }

  return {
    ...state,
    selectedMenuList: {
      items: menuList,
    },
  };
};

const handleReceiveMenuList = (state, menu) => {
  const categories = [];
  const menuByCategories = menu.map(category => {
    const categoryName = category.categoryName;
    categories.push(categoryName);
    //grouping by category name
    const subCategories = category.items.map(item => item.subcategory);
    const uniqSubCategories = [...new Set(subCategories)];
    const menuItemsBySubCategories = uniqSubCategories.map(item => {
      const menuItems = category.items.filter(menuItem => menuItem.subcategory === item);
      return {
        name: item,
        items: menuItems,
      };
    });

    return {
      categoryName: categoryName,
      subCategoriesNames: uniqSubCategories,
      subCategories: menuItemsBySubCategories,
      allCategoryItems: category.items,
    };
  });

  return {
    ...state,
    menu: {
      menuByCategories,
      categories,
    },
    selectedMenuList: {
      items: menuByCategories.length !== 0 ? menuByCategories[0].allCategoryItems : [],
    },
    fetching: false,
  };
};
