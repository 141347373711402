import { BRAND_ACTION_TYPES } from './brandConstants';

export const brandActions = {
  getBrands: () => ({
    type: BRAND_ACTION_TYPES.GET_BRANDS,
  }),
  getBrandsSuccess: data => ({
    type: BRAND_ACTION_TYPES.GET_BRANDS_SUCCESS,
    payload: data,
  }),
  createBrand: data => ({
    type: BRAND_ACTION_TYPES.CREATE_BRAND,
    payload: data,
  }),
  addPlace: (brandId, place) => ({
    type: BRAND_ACTION_TYPES.ADD_PLACE,
    payload: { brandId, place },
  }),
  selectBrand: data => ({
    type: BRAND_ACTION_TYPES.SELECT_BRAND,
    payload: data,
  }),
  getAllBrands: page => ({
    type: BRAND_ACTION_TYPES.GET_ALL_BRANDS,
    payload: page,
  }),
  getAllBrandsSuccess: data => ({
    type: BRAND_ACTION_TYPES.GET_ALL_BRANDS_SUCCESS,
    payload: data,
  }),
  getAllBrandsBySearch: searchString => ({
    type: BRAND_ACTION_TYPES.GET_ALL_BRANDS_BY_SEARCH,
    payload: searchString,
  }),
  getAllBrandsBySearchSuccess: data => ({
    type: BRAND_ACTION_TYPES.GET_ALL_BRANDS_BY_SEARCH_SUCCESS,
    payload: data,
  }),
  updateLoadingStatus: () => ({
    type: BRAND_ACTION_TYPES.UPDATE_LOADING_STATUS,
  }),
  getBrandsFromYelp: searchString => ({
    type: BRAND_ACTION_TYPES.GET_BRANDS_FROM_YELP,
    payload: searchString,
  }),
  getBrandsFromYelpSuccess: data => ({
    type: BRAND_ACTION_TYPES.GET_BRANDS_FROM_YELP_SUCCESS,
    payload: data,
  }),
  addBrandFromYelp: data => ({
    type: BRAND_ACTION_TYPES.ADD_BRAND_FROM_YELP,
    payload: data,
  }),
  clearAllBrands: () => ({
    type: BRAND_ACTION_TYPES.CLEAR_ALL_BRANDS,
  }),
  getBrandPlaces: brandId => ({
    type: BRAND_ACTION_TYPES.GET_BRAND_PLACES,
    payload: brandId,
  }),
  getBrandPlacesSuccess: data => ({
    type: BRAND_ACTION_TYPES.GET_BRAND_PLACES_SUCCESS,
    payload: data,
  }),
  getBrandOffers: brandId => ({
    type: BRAND_ACTION_TYPES.GET_BRAND_OFFERS,
    payload: brandId,
  }),
  getBrandOffersSuccess: data => ({
    type: BRAND_ACTION_TYPES.GET_BRAND_OFFERS_SUCCESS,
    payload: data,
  }),
};
