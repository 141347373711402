import React from 'react';
import { Button } from '../../../components/common/Button';
import './BrandCard.scss';

const BrandCard = props => {
  const { brand, addBrandFromYelp } = props;

  return (
    <div className="brand-card">
      <div className="brand-card__content">
        <img src={brand.imageUrl} className="brand-card__image" alt="" />
        <div className="brand-card__field">Name: {brand.name}</div>
        <a href={brand.externalUrl} className="brand-card__field">
          Link on yelp
        </a>
        <div className="brand-card__field">Rating: {brand.rating}</div>
        <div className="brand-card__field">
          <div className="brand-card__field-title">Categories:</div>
          <div className="brand-card__field-column">
            {brand.cuisines.map((cuisine, index) => (
              <div key={index}>{cuisine}</div>
            ))}
          </div>
        </div>
        <div className="brand-card__field">City: {brand.location.city}</div>
        <div className="brand-card__field">State: {brand.location.state}</div>
        <div className="brand-card__field">
          <div className="brand-card__field-title">Address:</div>
          <div className="brand-card__field-column">
            <div>{brand.location.address1}</div>
            <div>{brand.location.address2}</div>
            <div>{brand.location.address3}</div>
          </div>
        </div>
        <div className="brand-card__field">Price: {brand.priceLevel}</div>
      </div>
      <Button customClass="brand-card__button" onClick={() => addBrandFromYelp(brand.id)}>
        Add
      </Button>
    </div>
  );
};

export { BrandCard };
