import { all, takeLatest, put } from 'redux-saga/effects';
import { notificationActions } from '../notification/notificationActions';
import { NOTIFICATION_MESSAGES } from '../notification/notificationConstants';
import { overlayActions } from '../overlay/overlayActions';
import { menuApi } from '../../../api/menuApi';
import { menuActions } from './menuActions';
import { MENU_ACTION_TYPES } from './menuConstants';

function* getMenuListSaga({ payload }) {
  const brandId = payload;
  try {
    const response = yield menuApi.getMenuList(brandId);

    if (response) {
      yield put(menuActions.getMenuListSuccess(response.data));
    }
  } catch (error) {
    console.error('createDiscount', error);
  }
}

function* getMenuCategoriesSaga({ payload }) {
  const brandId = payload;
  try {
    const response = yield menuApi.getMenuCategories(brandId);

    if (response) {
      yield put(menuActions.getMenuCategoriesSuccess(response.data));
    }
  } catch (error) {
    console.error('menu categories', error);
  }
}

function* updateMenuCategoriesSaga({ payload }) {
  const { brandId, categories } = payload;
  yield put(overlayActions.showOverlay());
  try {
    const response = yield menuApi.updateMenuCategories(brandId, categories);

    if (response) {
      yield put(menuActions.updateMenuCategoriesSuccess(response.data));
    }
    yield put(notificationActions.showSuccessNotification('Menu categories have been updated'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('update menu categories', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* addMenuItemSaga({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    yield menuApi.addMenuItem(payload);
    yield put(notificationActions.showSuccessNotification('Menu item has been added'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('add menu item', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* updateMenuItemSaga({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    yield menuApi.updateMenuItem(payload);
    yield put(notificationActions.showSuccessNotification('Menu item has been updated'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('update menu item', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* removeMenuItemSaga({ payload }) {
  const { menuItemId, brandId } = payload;
  yield put(overlayActions.showOverlay());
  try {
    yield menuApi.removeMenuItem(menuItemId);
    yield put(menuActions.getMenuList(brandId));
    yield put(notificationActions.showSuccessNotification('Menu item has been removed'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('remove menu item', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* sagas() {
  yield all([
    takeLatest(MENU_ACTION_TYPES.GET_MENU_LIST, getMenuListSaga),
    takeLatest(MENU_ACTION_TYPES.GET_MENU_CATEGORIES, getMenuCategoriesSaga),
    takeLatest(MENU_ACTION_TYPES.UPDATE_MENU_CATEGORIES, updateMenuCategoriesSaga),
    takeLatest(MENU_ACTION_TYPES.ADD_MENU_ITEM, addMenuItemSaga),
    takeLatest(MENU_ACTION_TYPES.UPDATE_MENU_ITEM, updateMenuItemSaga),
    takeLatest(MENU_ACTION_TYPES.REMOVE_MENU_ITEM, removeMenuItemSaga),
  ]);
}

export const menuSagas = sagas;
