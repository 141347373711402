import axios from 'axios';
import { API_URL } from './baseApi';

export const offerApi = {
  createDiscount: async payload => {
    return await axios.post(`${API_URL}/biz/offers/discount`, payload);
  },
  createSet: async payload => {
    return await axios.post(`${API_URL}/biz/offers/set`, payload);
  },
  createHappyHour: async payload => {
    return await axios.post(`${API_URL}/biz/offers/happyhour`, payload);
  },
  updateHappyHour: async payload => {
    const { id, ...rest } = payload;
    return await axios.put(`${API_URL}/biz/offers/happyhour/${id}`, { ...rest });
  },
  updateOfferCouponInformation: async (payload, offerId) => {
    return await axios.post(`${API_URL}/biz/offers/${offerId}/coupon/metadata`, payload);
  },
  getOfferDetails: async offerId => {
    return await axios.get(`${API_URL}/biz/offers/${offerId}`);
  },
  removeOffer: async offerId => {
    return await axios.delete(`${API_URL}/biz/offers/${offerId}`);
  }
};
