import { OFFER_ACTION_TYPES } from './offerConstants';

const defaultState = {
  createdOfferId: '',
  offerDetails: {},
  fetching: true,
};

export const offerReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case OFFER_ACTION_TYPES.UPDATE_CREATED_OFFER_ID:
      return { ...state, createdOfferId: payload };
    case OFFER_ACTION_TYPES.GET_OFFER_DETAILS: {
      return { ...state, fetching: true };
    }
    case OFFER_ACTION_TYPES.GET_OFFER_DETAILS_SUCCESS: {
      return { ...state, offerDetails: payload, fetching: false };
    }
    default:
      return state;
  }
};
