import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Layout } from './MainLayout';
import { authActions } from '../../redux/modules/auth/authActions';

const mapDispatchToProps = {
  logout: authActions.logout,
};

const withConnect = connect(
  null,
  mapDispatchToProps,
);

export const MainLayout = compose(
  withRouter,
  withConnect,
)(Layout);
