import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { brandActions } from '../../../redux/modules/brand/brandActions';
import { BrandList } from './BrandList';

const mapStateToProps = state => {
  const {
    brand: { brands, isLoadingCompleted, fetching },
  } = state;

  return {
    brands,
    isLoadingCompleted,
    fetching,
  };
};

const mapDispatchToProps = {
  getAllBrands: brandActions.getAllBrands,
  selectBrand: brandActions.selectBrand,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const BrandListContainer = compose(
  withRouter,
  withConnect,
)(BrandList);
