import { connect } from 'react-redux';

import { BrandList } from './BrandList';
import { getBrands } from '../../redux/modules/brand/brandSelectors';
import { brandActions } from '../../redux/modules/brand/brandActions';

const mapStateToProps = state => {
  const brands = getBrands(state);
  return { brands };
};

const mapDispacthToProps = {
  getBrands: brandActions.getBrands,
  getAllBrands: brandActions.getAllBrands,
  clearAllBrands: brandActions.clearAllBrands
}

export const BrandListPage = connect(
  mapStateToProps,
  mapDispacthToProps,
)(BrandList);
