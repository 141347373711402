import React from 'react';
import PropTypes from 'prop-types';
import { AvailabilityContainer } from '../../../components/common/Availability';
import { Button } from '../../../components/common/Button';
import { SliderInput } from '../../../components/common/SliderInput';
import { OFFER_TYPES } from '../../../constants/offerTypes';
import { UrlFactory } from '../../../helpers/urlFactory';
import './DiscountForm.scss';

const DiscountForm = props => {
  const { createDiscount, formObj, title, history, brandId, placesIds, imageUrl } = props;
  const { startDate, endDate, discount } = formObj.values;

  const goToCouponDetailsStep = offerId => {
    history.push({
      pathname: UrlFactory.getAddCouponInformationUrl(brandId, offerId),
      search: '',
      state: {
        startDate,
        endDate,
        discount,
        title,
        offerType: OFFER_TYPES.DISCOUNT,
      },
    });
  };

  return (
    <React.Fragment>
      <SliderInput name="discount" labelBefore="Discount amount" symbolAfterValue="%" />
      <AvailabilityContainer formName="discountOfferForm" />
      <Button
        onClick={() => {
          createDiscount(title, placesIds, imageUrl, formObj.values, offerId => {
            goToCouponDetailsStep(offerId);
          });
        }}
        customClass="discount-form__submit-button"
      >
        Continue
      </Button>
    </React.Fragment>
  );
};

DiscountForm.defaultProps = {
  title: '',
  placesIds: [],
  imageUrl: '',
};

DiscountForm.propTypes = {
  createDiscount: PropTypes.func.isRequired,
  formObj: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
  placesIds: PropTypes.array.isRequired,
};

export { DiscountForm };
