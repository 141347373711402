import { createSelector } from 'reselect';
import { SUB_CATEGORIES } from './menuConstants';

export const getAllMenuItems = createSelector(
  [state => state.menu.menu],
  menu => {
    let allMenuItems = [];
    menu.menuByCategories.forEach(
      category => (allMenuItems = allMenuItems.concat(category.allCategoryItems)),
    );
    return allMenuItems;
  },
);

export const getSubCategoriesByCategory = createSelector(
  [state => state.menu.menu, (state, ownProps) => ownProps],
  (menu, { category, includeAllItemsSubCategory = true }) => {
    const selectedCategory =
      menu.menuByCategories.find(item => item.categoryName === category) || null;
    if (selectedCategory) {
      const subCategories = [...selectedCategory.subCategoriesNames];
      includeAllItemsSubCategory &&
        subCategories.length !== 0 &&
        subCategories.unshift(SUB_CATEGORIES.ALL_ITEMS);
      return subCategories;
    } else {
      return [];
    }
  },
);
