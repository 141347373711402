import { change } from 'redux-form';
import { connect } from 'react-redux';
import { PlacesSelector } from './PlacesSelector';

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    setPlacesIdsToFormData: ids => dispatch(change(formName, 'placesIds', ids)),
  };
};

const PlacesSelectorContainer = connect(
  null,
  mapDispatchToProps,
)(PlacesSelector);

export { PlacesSelectorContainer };
