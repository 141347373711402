import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom';

import App from './App';
import { store } from './redux/store';
import { unregister } from './serviceWorker';
import history from './helpers/history';
import { isAuthorizedSelector } from './redux/modules/auth/authSelectors';

const isAuthorized = isAuthorizedSelector(store.getState());

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router history={history}>
        <App isAuthorized={isAuthorized} />
      </Router>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

unregister();
