import axios from 'axios';

import { API_URL } from './baseApi';

export const dataApi = {
  getCuisines: async () => {
    return await axios.get(`${API_URL}/biz/cuisines`);
  },
  getCurrencies: async () => {
    return await axios.get(`${API_URL}/biz/currencies`);
  },
};
