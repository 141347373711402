import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../components/common/Button';
import { HeaderContainer } from './Header';
import { BrandListContainer } from './BrandList';
import { UrlFactory } from '../../helpers/urlFactory';
import './AllBrandsPage.scss';

const AllBrands = props => {
  const { history } = props;

  return (
    <div className="all-brands">
      <HeaderContainer />
      <BrandListContainer />
      <div className="all-brands__footer">
        <Button customClass="all-brands__button" onClick={() => history.push(UrlFactory.getAddBrandUrl())}>
          <div className="all-brands__button-title">Add new brand</div>
        </Button>
      </div>
    </div>
  );
};

AllBrands.propTypes = {
  history: PropTypes.object.isRequired,
};

export { AllBrands };
