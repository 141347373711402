import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from '../../../components/common/Spinner';
import { UrlFactory } from '../../../helpers/urlFactory';
import './BrandList.scss';

const BrandList = props => {
  const { brands, getAllBrands, history, isLoadingCompleted, fetching, selectBrand } = props;
  const [selectedBrandIndex, setSelectedBrandIndex] = useState(null);
  const [page, setPage] = useState(1);

  const handleLoadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    getAllBrands(nextPage);
  };

  const handleSelectActiveItem = (path, brand) => {
    selectBrand(brand);
    history.push(path);
  };

  const renderBrandList = () => {
    return brands.map((brand, index) => {
      return (
        <div
          className="brand-list__item"
          key={index}
          onMouseEnter={() => setSelectedBrandIndex(index)}
          onMouseLeave={() => setSelectedBrandIndex(null)}
        >
          <div className="brand-list__item-img-with-title">
            <img src={brand.imageUrl} className="brand-list__item-img" alt="" />
            <div className="brand-list__item-title">{brand.name}</div>
          </div>
          {index === selectedBrandIndex && (
            <div className="brand-list__action-buttons">
              <div className="brand-list__action-button">Addresses ({brand.placesCount})</div>
              <div className="brand-list__action-button">Edit</div>
              <div
                className="brand-list__action-button"
                onClick={() => {
                  handleSelectActiveItem(UrlFactory.getAddPlaceUrl(brand.id), brand);
                }}
              >
                Add place
              </div>
              <div
                className="brand-list__action-button"
                onClick={() => {
                  handleSelectActiveItem(UrlFactory.getMenuUrl(brand.id), brand);
                }}
              >
                View menu
              </div>
              <div
                className="brand-list__action-button"
                onClick={() =>
                  handleSelectActiveItem(UrlFactory.getBrandOffersUrl(brand.id), brand)
                }
              >
                View offers
              </div>
              <div
                className="brand-list__action-button"
                onClick={() => handleSelectActiveItem(UrlFactory.getAddOfferUrl(brand.id), brand)}
              >
                Add offer
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="brand-list">
      <InfiniteScroll
        dataLength={brands.length}
        next={handleLoadMore}
        scrollThreshold={0.8}
        style={{
          overflow: 'hidden',
        }}
        hasMore={!isLoadingCompleted}
      >
        {renderBrandList()}
        {fetching && (
          <div className="brand-list__loader">
            <Spinner />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

BrandList.propTypes = {
  brands: PropTypes.array.isRequired,
  getAllBrands: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoadingCompleted: PropTypes.bool.isRequired,
};

export { BrandList };
