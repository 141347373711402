import React from 'react';

export const ThreeDotsIcon = props => (
  <svg
    width="15"
    height="3"
    viewBox="0 0 15 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="1.5" cy="1.5" r="1.5" fill="curentColor" />
    <circle cx="7.5" cy="1.5" r="1.5" fill="curentColor" />
    <circle cx="13.5" cy="1.5" r="1.5" fill="curentColor" />
  </svg>
);
