import { UrlFactory } from '../helpers/urlFactory';

const regExpForId = '(w*[0-9]*.*-*)';

const LINKS = {
  HOME: {
    getPath: () => UrlFactory.getHomeUrl(),
    getName: () => 'Home',
  },
  ADD_BRAND: {
    getPath: () => UrlFactory.getAddBrandUrl(),
    getName: () => 'Add brand',
  },
  ADD_BRAND_FROM_YELP: {
    getPath: () => UrlFactory.getAddBrandFromYelpUrl(),
    getName: () => 'Import from Yelp',
  },
  BRANDS: {
    getPath: () => UrlFactory.getBrandsUrl(),
    getName: () => 'Your brands',
  },
  BRAND_OFFERS: {
    getPath: ({ brandId }) => UrlFactory.getBrandOffersUrl(brandId),
    getName: () => 'Browse offers',
  },
  ADD_PLACE: {
    getPath: ({ brandId }) => UrlFactory.getAddPlaceUrl(brandId),
    getName: () => 'Add place',
  },
  ADD_OFFER: {
    getPath: ({ brandId }) => UrlFactory.getAddOfferUrl(brandId),
    getName: () => 'Add offer',
  },
  BRAND_OFFERS_EDIT: {
    getPath: ({ brandId, offerId }) => UrlFactory.getEditOfferUrl(brandId, offerId),
    getName: () => 'Edit offer',
  },
  MENU: {
    getPath: ({ brandId }) => UrlFactory.getMenuUrl(brandId),
    getName: () => 'Browse menu',
  },
  MENU_ADD_CATEGORY: {
    getPath: ({ brandId }) => UrlFactory.getAddMenuCategoryUrl(brandId),
    getName: () => 'Add category',
  },
  MENU_ADD_MENU_ITEM: {
    getPath: ({ brandId }) => UrlFactory.getAddMenuItemUrl(brandId),
    getName: () => 'Add menu item',
  },
  MENU_EDIT_MENU_ITEM: {
    getPath: ({ brandId }) => UrlFactory.getEditMenuItemUrl(brandId),
    getName: () => 'Edit menu item',
  },
  COUPON_INFORMATION: {
    getPath: ({ brandId, offerId }) => UrlFactory.getAddCouponInformationUrl(brandId, offerId),
    getName: () => 'Coupon information',
  },
  BRAND_INFO: {
    getPath: () => UrlFactory.getBrandInfoUrl(),
    getName: name => name,
  },
};

export const ROUTES = [
  {
    path: '/',
    links: [LINKS.HOME],
  },
  {
    path: '/brands',
    links: [LINKS.HOME, LINKS.BRANDS],
  },
  {
    path: `/brands/${regExpForId}/places/add`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.ADD_PLACE],
  },
  {
    path: '/brands/add',
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.ADD_BRAND],
  },
  {
    path: '/brands/add/yelp',
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.ADD_BRAND, LINKS.ADD_BRAND_FROM_YELP],
  },
  {
    path: `/brands/${regExpForId}/offers/add`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.ADD_OFFER],
  },
  {
    path: `/brands/${regExpForId}/menu`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.MENU],
  },
  {
    path: `/brands/${regExpForId}/menu/add`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.MENU, LINKS.MENU_ADD_MENU_ITEM],
  },
  {
    path: `/brands/${regExpForId}/menu/edit`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.MENU, LINKS.MENU_EDIT_MENU_ITEM],
  },
  {
    path: `/brands/${regExpForId}/menu/categories`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.MENU, LINKS.MENU_ADD_CATEGORY],
  },
  {
    path: `/brands/${regExpForId}/offers/${regExpForId}/coupon-info`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.COUPON_INFORMATION],
  },
  {
    path: `/brands/${regExpForId}/offers`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.BRAND_OFFERS],
  },
  {
    path: `/brands/${regExpForId}/offers/${regExpForId}/edit`,
    links: [LINKS.HOME, LINKS.BRANDS, LINKS.BRAND_INFO, LINKS.BRAND_OFFERS, LINKS.BRAND_OFFERS_EDIT]
  }
];
