import React from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from '../Icons/ArrowIcon';
import { Selector } from '../Selector';
import { Button } from '../Button';
import { UrlFactory } from '../../../helpers/urlFactory';
import './MenuItemCategorySelector.scss';

const MenuItemCategorySelector = props => {
  const { categories, selectedCategoryId, history, brandId } = props;
  const categoriesOptions = categories.map(category => {
    return {
      label: category.name,
      value: category.id,
    };
  });

  const selectedCategory = categories.find(category => category.id === selectedCategoryId);

  const subCategoriesOptions = selectedCategory
    ? selectedCategory.subcategories.map(subCategory => {
        return {
          label: subCategory.name,
          value: subCategory.id,
        };
      })
    : [];

  return (
    <div className="menu-item-category-selector">
      <div className="menu-item-category-selector__title-container">
        <div className="menu-item-category-selector__title">Select category</div>
        <ArrowIcon />
      </div>
      <div className="menu-item-category-selector__select-with-button-container">
        <Selector
          labelBefore="Category"
          customClass="menu-item-category-selector__select-input"
          emptyLabel="Select category"
          name="selectedCategoryId"
          options={categoriesOptions}
        />
        <div className="menu-item-category-selector__sub-text">or</div>
        <Button
          customClass="menu-item-category-selector__button"
          onClick={() => history.push(UrlFactory.getAddMenuCategoryUrl(brandId))}
        >
          Add new
        </Button>
      </div>
      {subCategoriesOptions.length !== 0 && (
        <Selector
          labelBefore="Subcategory"
          customClass="menu-item-category-selector__select-input"
          emptyLabel="Select subcategory"
          name="selectedSubCategoryId"
          options={subCategoriesOptions}
        />
      )}
    </div>
  );
};

MenuItemCategorySelector.propTypes = {
  categories: PropTypes.array.isRequired,
  selectedCategoryId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  brandId: PropTypes.string.isRequired,
};

export { MenuItemCategorySelector };
