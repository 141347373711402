import React from 'react';

export const UploadPhotoIcon = props => (
  <svg
    width="98"
    height="98"
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      opacity="0.2"
      x="0.5"
      y="0.5"
      width="97"
      height="97"
      rx="9.5"
      fill="white"
      stroke="#061226"
      strokeDasharray="5 5"
    />
    <circle opacity="0.05" cx="49" cy="49" r="25" fill="#061226" />
    <rect x="39" y="39" width="20" height="20" rx="2" stroke="#919699" strokeWidth="2" />
    <path d="M41.5 56.5L44.5 52.5L47.5 56.5L52 50.5L56.5 56.5H47.5H41.5Z" fill="#919699" />
    <path
      d="M47.5 56.5L44.5 52.5L41.5 56.5H47.5ZM47.5 56.5L52 50.5L56.5 56.5H47.5Z"
      stroke="#919699"
      strokeLinejoin="round"
    />
  </svg>
);
