import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ArrowIcon } from '../../components/common/Icons/ArrowIcon';
import { Button } from '../../components/common/Button';
import { Spinner } from '../../components/common/Spinner';
import { OkeyIcon } from '../../components/common/Icons/OkIcon';
import { CustomCategories } from './CustomCategories';
import { SubCategories } from './SubCategories';
import './AddCategoryPage.scss';

const AddCategory = props => {
  const {
    categoryName,
    categoryId,
    pushCategoryToFormData,
    selectedBrand,
    clearCategoryName,
    brandId,
    getMenuCategories,
    categories,
    pushCategoriesToFormData,
    updateMenuCategories,
    updatedCategories,
    fetching,
    clearCategoryId,
  } = props;
  const brandName = selectedBrand ? selectedBrand.name : '';

  useEffect(() => {
    getMenuCategories(brandId);
  }, [getMenuCategories, brandId]);

  useEffect(() => {
    pushCategoriesToFormData(categories);
  }, [categories, pushCategoriesToFormData]);

  return (
    <div className="add-category">
      <div className="add-category__title">{`Add category for ${brandName}`}</div>
      <div className="add-category__form">
        <CustomCategories
          categoryName={categoryName}
          pushCategoryToFormData={pushCategoryToFormData}
          clearCategoryName={clearCategoryName}
        />
        <div className="add-category__summary-title-container">
          <div className="add-category__summary-title">Summary</div>
          <ArrowIcon />
        </div>
        {updatedCategories.length === 0 ? (
          <div className="add-category__empty-summary">
            <OkeyIcon />
            <div className="add-category__empty-summary-title">
              Selected categories will be displayed here.
            </div>
          </div>
        ) : fetching ? (
          <Spinner />
        ) : (
          <SubCategories categories={updatedCategories} categoryId={categoryId} />
        )}

        <Button
          customClass="add-category__save-button"
          disabled={updatedCategories.length === 0}
          onClick={() => {
            updateMenuCategories(brandId, updatedCategories);
            clearCategoryId();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

AddCategory.defaultProps = {
  categoryId: '0',
  categoryName: '',
  updatedCategories: [],
};

AddCategory.propTypes = {
  categoryName: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  pushCategoryToFormData: PropTypes.func.isRequired,
  selectedBrand: PropTypes.object,
  clearCategoryName: PropTypes.func.isRequired,
  getMenuCategories: PropTypes.func.isRequired,
  brandId: PropTypes.string.isRequired,
  updatedCategories: PropTypes.array.isRequired,
  fetching: PropTypes.bool.isRequired,
  clearCategoryId: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  pushCategoriesToFormData: PropTypes.func.isRequired,
  updateMenuCategories: PropTypes.func.isRequired,
};

export { AddCategory };
