import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../components/common/Button';
import { USER_ROLES } from '../../../constants/userRoles';
import { isUserInRole } from '../../../helpers/userRolesHelper';
import { UrlFactory } from '../../../helpers/urlFactory';
import './BrandItem.scss';

export const BrandItem = props => {
  const {
    brand: { name, imageUrl, placesCount, id: brandId },
    brand,
    history,
    selectBrand,
  } = props;

  const onAddPlaceClick = () => {
    selectBrand(brand);
    history.push(UrlFactory.getAddPlaceUrl(brandId));
  };

  const onAddOfferClick = () => {
    selectBrand(brand);
    history.push(UrlFactory.getAddOfferUrl(brandId));
  };

  return (
    <div className="brand-item" key={name}>
      <img src={imageUrl} className="brand-item__picture" alt={name} />
      <div className="brand-item__brand-info">
        <div className="brand-item__brand-info-top">
          <div className="brand-item__brand-info-top-left">
            <div className="brand-item__brand-name">{name}</div>
            <div className="brand-item__brand-links">
              <div className="brand-item__link">View menu</div>
              <div className="brand-item__link">
                {placesCount === 1 ? placesCount + ' address' : placesCount + ' addresses'}
              </div>
            </div>
          </div>
          <div className="brand-item__brand-info-top-right">
            {isUserInRole(USER_ROLES.ADMIN) && (
              <div className="brand-item__link-add-place" onClick={() => onAddPlaceClick()}>
                Add place
              </div>
            )}
            <div className="brand-item__link-edit">Edit</div>
          </div>
        </div>

        <div className="brand-item__brand-info-bottom">
          <div className="brand-item__link-view-offers">View offers</div>
          <Button onClick={() => onAddOfferClick()} customClass="brand-item__add-offer-btn">
            Add offer
          </Button>
        </div>
      </div>
    </div>
  );
};

BrandItem.propTypes = {
  brand: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectBrand: PropTypes.func.isRequired,
};
