import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { ArrowIcon } from '../../components/common/Icons/ArrowIcon';
import { Input } from '../../components/common/Input';
import { ImageUpload } from '../../components/common/ImageUpload';
import { HappyHourFormContainer } from '../AddOfferPage/HappyHourForm';
import { PlacesSelectorContainer } from '../../components/common/PlacesSelector';
import { TextArea } from '../../components/common/TextArea';
import { getDaysWithTimeIntervals, formatToUTC } from '../../helpers/dayHelper';
import './EditOfferPage.scss';
import { Button } from '../../components/common/Button';
import { Spinner } from '../../components/common/Spinner';

export const EditOfferPageComponent = props => {
  const {
    selectedBrand,
    brandId,
    getBrandPlaces,
    selectedBrandPlaces,
    offerId,
    getOfferDetails,
    offerDetailsFetching,
    offerDetails,
    setOfferTitle,
    setOfferDescription,
    setOfferImage,
    setPlacesIdsToFormData,
    setMenuItemsIds,
    setDiscountedPrice,
    pushDaysToFormData,
    setStartDate,
    setEndDate,
    setExcludedCustomDays,
    updateHappyHour,
    formObj,
    happyHourFormObj,
    setDiscountedPrices,
  } = props;
  const brandName = selectedBrand ? selectedBrand.name : '';

  useEffect(() => {
    if (brandId) {
      getBrandPlaces(brandId);
    }

    if (offerId) {
      getOfferDetails(offerId);
    }
  }, [getBrandPlaces, brandId, offerId, getOfferDetails]);

  useEffect(() => {
    if (!offerDetailsFetching) {
      setOfferTitle(offerDetails.title);
      setOfferDescription(offerDetails.happyHour.description);
      setOfferImage(offerDetails.imageUrl);
      setPlacesIdsToFormData(offerDetails.placesIds);
      const offerDays = getDaysWithTimeIntervals(offerDetails.availability.offerDays);
      pushDaysToFormData(offerDays);
      const menuItemsIds = offerDetails.happyHour.menuItems.map((item, index) => {
        setDiscountedPrice(index, item.discountedPrice);
        return item.id;
      });
      setMenuItemsIds(menuItemsIds);
      setDiscountedPrices(offerDetails.happyHour.menuItems);
      setStartDate(formatToUTC(offerDetails.availability.startDate));
      setEndDate(formatToUTC(offerDetails.availability.endDate));
      const formatedDates = offerDetails.availability.excludedDates.map(day => formatToUTC(day));
      setExcludedCustomDays(formatedDates);
    }
  }, [
    offerDetailsFetching,
    offerDetails,
    setOfferDescription,
    setOfferTitle,
    setOfferImage,
    setPlacesIdsToFormData,
    pushDaysToFormData,
    setDiscountedPrice,
    setMenuItemsIds,
    setStartDate,
    setEndDate,
    setExcludedCustomDays,
    setDiscountedPrices,
  ]);

  if (!selectedBrand || offerDetailsFetching) {
    return (
      <div className="edit-offer__spinner">
        <Spinner />
      </div>
    );
  } else
    return (
      <div className="edit-offer">
        <div className="edit-offer__header">Add Offer for {brandName}</div>
        <div className="edit-offer__form">
          <PlacesSelectorContainer places={selectedBrandPlaces} formName="editOfferForm" />
          <div className="edit-offer__basic-info-container">
            <div className="edit-offer__container-header">
              <div className="edit-offer__title">Basic information </div>
              <ArrowIcon />
            </div>
            <Input
              name="title"
              labelBefore="Offer title"
              customClass="edit-offer__input"
              withClearing={true}
            />
            <TextArea name="description" labelBefore="Offer description" />
            <ImageUpload
              name="image"
              customClass="edit-offer__photo-upload"
              description="Upload a photo of your offer in order to gain more attention."
            />
          </div>

          <div className="edit-offer__offer-details-container">
            <div className="edit-offer__container-header">
              <div className="edit-offer__title">Offer details </div>
              <ArrowIcon />
            </div>
            <div className="edit-offer__offer-container">
              <HappyHourFormContainer brandId={brandId} />
            </div>
          </div>
          <Button
            onClick={() => updateHappyHour(offerDetails.id, formObj, happyHourFormObj)}
            customClass="edit-offer__submit-button"
          >
            Save
          </Button>
        </div>
      </div>
    );
};

EditOfferPageComponent.defaultProps = {
  title: '',
};

EditOfferPageComponent.propTypes = {
  selectedBrand: PropTypes.object,
  brandId: PropTypes.string.isRequired,
  getBrandPlaces: PropTypes.func.isRequired,
};
