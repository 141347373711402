import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import { cuisinesReducer } from './modules/cuisines/cuisinesReducer';
import { currenciesReducer } from './modules/currencies/currenciesReducer';
import { brandReducer } from './modules/brand/brandReducer';
import { authReducer } from './modules/auth/authReducer';
import { menuReducer } from './modules/menu/menuReducer';
import { offerReducer } from './modules/offer/offerReducer';
import { overlayReducer } from './modules/overlay/overlayReducer';
import { placeReducer } from './modules/places/placeReducer';

export const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    cuisines: cuisinesReducer,
    currencies: currenciesReducer,
    brand: brandReducer,
    auth: authReducer,
    menu: menuReducer,
    offer: offerReducer,
    overlay: overlayReducer,
    place: placeReducer,
  });
