import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { AddPlace } from './AddPlacePage';
import { brandActions } from '../../redux/modules/brand/brandActions';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const brandId = match.params.brandId;
  const selectedBrand = getBrandById(state, brandId);

  return {
    selectedBrand,
    brandId,
  };
};

const mapDispatchToProps = {
  addPlace: brandActions.addPlace,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const AddPlacePage = compose(
  withRouter,
  withConnect,
)(AddPlace);
