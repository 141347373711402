import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../components/common/Button';
import { Input } from '../../components/common/Input';
import { ArrowIcon } from '../../components/common/Icons/ArrowIcon';
import { OfferOverview } from './OfferOverview/OfferOverview';
import { AvailabilityContainer } from '../../components/common/Availability';
import './CouponInformationPage.scss';

const CouponInformation = props => {
  const {
    history: {
      location: { state: offerInformation },
      goBack,
    },
    formObj,
    offerId,
    updateOfferCouponInformation,
  } = props;

  return (
    <div className="coupon-information">
      <div className="coupon-information__title">Coupon information</div>
      <div className="coupon-information__form">
        <OfferOverview offerInformation={offerInformation} />
        <div className="coupon-information__coupon-amount">
          <div className="coupon-information__coupon-amount-title-container">
            <div className="coupon-information__coupon-amount-title">Coupon amount</div>
            <ArrowIcon />
          </div>
          <Input
            name="couponAmount"
            labelBefore="Amount of coupons released"
            customClass="coupon-information__input"
            withClearing
          />
        </div>
        <AvailabilityContainer formName="couponInformationForm" title="Coupon valid" />
        <div className="coupon-information__buttons-container">
          <Button customClass="coupon-information__button" onClick={() => goBack()}>
            Back
          </Button>
          <Button
            customClass="coupon-information__button--success"
            onClick={() => {
              const {
                startDate,
                endDate,
                couponAmount,
                excludedDaysOfWeek,
                excludedCustomDays,
              } = formObj.values;

              const payload = {
                quantityToRelease: couponAmount,
                whenValid: {
                  startDate,
                  endDate,
                  excludedDates: [...excludedDaysOfWeek, ...excludedCustomDays],
                },
              };

              updateOfferCouponInformation(payload, offerId);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

CouponInformation.propTypes = {
  formObj: PropTypes.object.isRequired,
  offerId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  updateOfferCouponInformation: PropTypes.func.isRequired,
};

export { CouponInformation };
