import React from 'react';
import { Search } from '../../../components/common/Search';
import './Header.scss';

const Header = props => {
  return (
    <div className="header">
      <div className="header__title">All offers</div>
      <Search name="search" placeholder="Search offers" withFilter />
    </div>
  );
};

export { Header };
