import React from 'react';
import PropTypes from 'prop-types';
import { getSetOfferOverviewData, getDiscountOfferOverviewData } from './offerOverviewHelper';
import { ArrowIcon } from '../../../components/common/Icons/ArrowIcon';
import { OFFER_TYPES } from '../../../constants/offerTypes';
import './OfferOverview.scss';

const OfferOverview = props => {
  const { offerInformation } = props;

  const renderItems = () => {
    let items = [];

    switch (offerInformation.offerType) {
      case OFFER_TYPES.SET: {
        items = getSetOfferOverviewData(offerInformation);
        break;
      }
      case OFFER_TYPES.DISCOUNT: {
        items = getDiscountOfferOverviewData(offerInformation);
        break;
      }

      default: {
        items = [];
        break;
      }
    }

    return items.map((item, index) => (
      <div className="offer-overview__item" key={index}>
        <div className="offer-overview__item-title">{item.title}</div>
        <div className="offer-overview__item-description">{item.description}</div>
      </div>
    ));
  };

  return (
    <div className="offer-overview">
      <div className="offer-overview__title-container">
        <div className="offer-overview__title">Offer overview</div>
        <ArrowIcon />
      </div>
      <div className="offer-overview__items-container">{renderItems()}</div>
    </div>
  );
};

OfferOverview.defaultProps = {
  offerInformation: {
    offerType: '',
  },
};

OfferOverview.propTypes = {
  offerInformation: PropTypes.object.isRequired,
};

export { OfferOverview };
