import { PLACE_ACTION_TYPES } from './placeConstants';

export const placeActions = {
  getPlaces: () => ({
    type: PLACE_ACTION_TYPES.GET_PLACES,
  }),
  getPlacesSuccess: data => ({
    type: PLACE_ACTION_TYPES.GET_PLACES_SUCCESS,
    payload: data,
  }),
};
