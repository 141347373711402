import React from 'react';

export const FilterIcon = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4 6.5H21" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
    <path d="M11.5 18.5L13.5 18.5" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
    <path d="M8 12.5L17 12.5" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
