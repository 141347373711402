import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

export const NonAuthorizedRoute = props => {
  const { component: Component, isAuthorized, ...rest } = props;

  return (
    <Route
      {...rest}
      render={routeProps => {
        if (!isAuthorized) {
          return <Component {...routeProps} />;
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

NonAuthorizedRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
