import { all, put, takeLatest } from 'redux-saga/effects';

import { brandApi } from '../../../api/brandApi';
import { brandActions } from './brandActions';
import { BRAND_ACTION_TYPES } from './brandConstants';
import { notificationActions } from '../notification/notificationActions';
import { NOTIFICATION_MESSAGES } from '../notification/notificationConstants';
import { overlayActions } from '../overlay/overlayActions';

function* getBrands() {
  try {
    const response = yield brandApi.getBrands();

    if (response) {
      yield put(brandActions.getBrandsSuccess(response.data));
    }
  } catch (err) {
    console.error('get brands error', err);
  }
}

function* getAllBrands({ payload }) {
  const page = payload;

  try {
    const response = yield brandApi.getAllBrands('', page);

    if (response) {
      yield put(brandActions.getAllBrandsSuccess(response.data));
    }
  } catch (err) {
    console.error('get brand list error', err);
  }
}

function* getBrandsFromYelp({ payload }) {
  const searchString = payload;

  try {
    const response = yield brandApi.getBrandsFromYelp(searchString);

    if (response) {
      yield put(brandActions.getBrandsFromYelpSuccess(response.data));
    }
  } catch (err) {
    console.error('get brands from yelp error', err);
  }
}

function* getAllBrandsBySearch({ payload }) {
  const searchString = payload;

  try {
    const response = yield brandApi.getAllBrands(searchString, 1);

    if (response) {
      yield put(brandActions.getAllBrandsBySearchSuccess(response.data));

      if (searchString === '') {
        yield put(brandActions.updateLoadingStatus());
      }
    }
  } catch (err) {
    console.error('get brand list error', err);
  }
}

function* createBrand({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    const { name, managerId, image: imageUrl } = payload,
      cuisinesIds = payload.cuisines,
      currencyId = payload.currency;
    const response = yield brandApi.createBrand(
      name.trim(),
      managerId.trim(),
      cuisinesIds,
      currencyId,
      imageUrl,
    );

    if (response) {
    }
    yield put(notificationActions.showSuccessNotification('Brand has been added'));
  } catch (err) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('create brand error', err);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* addPlace({ payload }) {
  try {
    const { brandId, place } = payload;
    const response = yield brandApi.addPlace(brandId, place);

    if (response) {
    }
  } catch (err) {
    console.error('add place error', err);
  }
}

function* addBrandFromYelp({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    const brandId = payload;
    yield brandApi.addBrandFromYelp(brandId);
    yield put(notificationActions.showSuccessNotification('Brand from yelp has been added'));
  } catch (err) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('add place error', err);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* getBrandPlaces({ payload }) {
  try {
    const brandId = payload;
    const response = yield brandApi.getBrandPlaces(brandId);
    yield put(brandActions.getBrandPlacesSuccess(response.data));
  } catch (err) {
    console.error('brand places error', err);
  }
}

function* getBrandOffers({ payload }) {
  try {
    const brandId = payload;
    const response = yield brandApi.getBrandOffers(brandId);
    yield put(brandActions.getBrandOffersSuccess(response.data));
  } catch (err) {
    console.error('brand offers error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(BRAND_ACTION_TYPES.GET_BRANDS, getBrands),
    takeLatest(BRAND_ACTION_TYPES.CREATE_BRAND, createBrand),
    takeLatest(BRAND_ACTION_TYPES.ADD_PLACE, addPlace),
    takeLatest(BRAND_ACTION_TYPES.GET_ALL_BRANDS, getAllBrands),
    takeLatest(BRAND_ACTION_TYPES.GET_ALL_BRANDS_BY_SEARCH, getAllBrandsBySearch),
    takeLatest(BRAND_ACTION_TYPES.GET_BRANDS_FROM_YELP, getBrandsFromYelp),
    takeLatest(BRAND_ACTION_TYPES.ADD_BRAND_FROM_YELP, addBrandFromYelp),
    takeLatest(BRAND_ACTION_TYPES.GET_BRAND_PLACES, getBrandPlaces),
    takeLatest(BRAND_ACTION_TYPES.GET_BRAND_OFFERS, getBrandOffers),
  ]);
}

export const brandSagas = sagas;
