import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MultiselectableMenu } from '../../../components/common/MultiselectableMenu';
import { AvailabilityContainer } from '../../../components/common/Availability';
import { Button } from '../../../components/common/Button';
import { Spinner } from '../../../components/common/Spinner';
import { Input } from '../../../components/common/Input';
import { getCurrencySymbol } from '../../../helpers/currencyHelper';
import { OFFER_TYPES } from '../../../constants/offerTypes';
import { UrlFactory } from '../../../helpers/urlFactory';
import './SetForm.scss';

const SetForm = props => {
  const [regularPrice, setRegularPrice] = useState(0);
  const {
    fetching,
    getMenuList,
    brandId,
    selectedMenuItemsIds,
    selectedMenuList,
    allMenuItems,
  } = props;
  const currencySymbol = getCurrencySymbol('USD');

  useEffect(() => {
    if (selectedMenuList.items) {
      const regularPrices = selectedMenuItemsIds.map(
        item => allMenuItems.find(menuItem => menuItem.id === item).price,
      );
      const regularPrice = regularPrices.reduce((a, b) => a + b, 0);
      setRegularPrice(regularPrice);
    }
  }, [selectedMenuItemsIds, selectedMenuList, allMenuItems]);

  useEffect(() => {
    getMenuList(brandId);
  }, [brandId, getMenuList]);

  if (fetching) {
    return (
      <div className="set-form__spinner">
        <Spinner />
      </div>
    );
  } else {
    const {
      formObj,
      createSet,
      title,
      selectedMenuList,
      selectedMenuItemsIds,
      updateSelectedMenuList,
      history,
      subCategories,
      selectedCategoryName,
      categories,
      setInitialCategory,
      placesIds,
      imageUrl,
    } = props;

    const goToCouponDetailsStep = offerId => {
      const { startDate, endDate, price } = formObj.values;

      history.push({
        pathname: UrlFactory.getAddCouponInformationUrl(brandId, offerId),
        search: '',
        state: {
          startDate,
          endDate,
          regularPrice,
          discountedPrice: price,
          includedItems: selectedMenuItemsNames,
          title,
          offerType: OFFER_TYPES.SET,
        },
      });
    };

    const selectedMenuItemsNames = selectedMenuItemsIds.map(item => {
      return allMenuItems.find(menuItem => menuItem.id === item).name;
    });

    return (
      <div className="set-form">
        <div className="set-form__menu-title">Select Set items</div>
        <MultiselectableMenu
          currencySymbol={currencySymbol}
          selectedMenuList={selectedMenuList}
          updateSelectedMenuList={updateSelectedMenuList}
          categories={categories}
          subCategories={subCategories}
          selectedCategoryName={selectedCategoryName}
          setInitialCategory={setInitialCategory}
          numberOfCards={4}
        />
        {regularPrice !== 0 && (
          <React.Fragment>
            <div className="set-form__price">{`Set price (Regular price ${currencySymbol}${regularPrice})`}</div>
            <Input name="price" customClass="set-form__price-input" labelBefore={currencySymbol} />
          </React.Fragment>
        )}
        <AvailabilityContainer formName="setOfferForm" />
        <Button
          onClick={() => {
            createSet(title, placesIds, imageUrl, formObj.values, selectedMenuItemsIds, offerId => {
              goToCouponDetailsStep(offerId);
            });
          }}
          customClass="set-form__submit-button"
        >
          Continue
        </Button>
      </div>
    );
  }
};

SetForm.defaultProps = {
  selectedMenuItemsIds: [],
  selectedCategoryName: '',
  placesIds: [],
  imageUrl: ''
};

SetForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getMenuList: PropTypes.func.isRequired,
  brandId: PropTypes.string.isRequired,
  selectedMenuItemsIds: PropTypes.array.isRequired,
  selectedMenuList: PropTypes.object.isRequired,
  formObj: PropTypes.object.isRequired,
  createSet: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  updateSelectedMenuList: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  subCategories: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  setInitialCategory: PropTypes.func.isRequired,
  allMenuItems: PropTypes.array.isRequired,
  placesIds: PropTypes.array.isRequired,
};

export { SetForm };
