import React from 'react';

export const CloseIcon = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5.5 5.5L19.5 19.5" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
    <path d="M19.5 5.5L5.5 19.5" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
