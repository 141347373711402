import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Selector } from '../../../components/common/Selector';
import { Carousel } from '../../../components/common/Carousel';
import './CategorySelector.scss';

const CategorySelector = props => {
  const [selectedSubCategoryIndex, setSelectedSubCategoryIndex] = useState(0);
  const {
    categories,
    selectedCategoryName,
    updateSelectedMenuList,
    setInitialCategory,
    subCategories,
    numberOfCards,
  } = props;

  const categoriesOptions = categories.map(item => {
    return {
      label: item,
      value: item,
    };
  });

  useEffect(() => {
    setSelectedSubCategoryIndex(0);
  }, [selectedCategoryName]);

  useEffect(() => {
    if (categories) {
      setInitialCategory(categories[0]);
    }
  }, [categories, setInitialCategory]);

  const renderCategories = () => {
    return (
      categories.length !== 0 && (
        <React.Fragment>
          <div className="category-selector__title">Categories</div>
          <Selector
            name="selectedCategoryName"
            customClass="category-selector"
            options={categoriesOptions}
            customChange={categoryName =>
              updateSelectedMenuList({
                categoryName,
              })
            }
          />
        </React.Fragment>
      )
    );
  };

  const renderSubCategories = () => {
    return (
      subCategories.length !== 0 && (
        <React.Fragment>
          <div className="category-selector__sub-title">Subcategories</div>
          <div className="category-selector__carousel">
            <Carousel
              options={subCategories}
              activeItemIndex={selectedSubCategoryIndex}
              setActiveItemIndex={setSelectedSubCategoryIndex}
              numberOfCards={numberOfCards}
              customChange={subCategory =>
                updateSelectedMenuList({
                  categoryName: selectedCategoryName,
                  subCategoryName: subCategory,
                })
              }
            />
          </div>
        </React.Fragment>
      )
    );
  };

  return (
    <div className="category-selector">
      {renderCategories()}
      {renderSubCategories()}
    </div>
  );
};

CategorySelector.propTypes = {
  categories: PropTypes.array.isRequired,
  subCategories: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  updateSelectedMenuList: PropTypes.func.isRequired,
  setInitialCategory: PropTypes.func.isRequired,
  numberOfCards: PropTypes.number,
};

export { CategorySelector };
