import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { WorkingHours } from './WorkingHours';

const mapStateToProps = (state, ownProps) => {
  const formSelector = formValueSelector(ownProps.formName);

  return {
    days: formSelector(state, 'days'),
    commonStartTime: formSelector(state, 'commonStartTime'),
    commonEndTime: formSelector(state, 'commonEndTime'),
    daysWithTimeIntervals: formSelector(state, 'daysWithTimeIntervals'),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { formName } = ownProps;
  return {
    pushDaysToFormData: daysWithTimeIntervals =>
      dispatch(change(formName, 'daysWithTimeIntervals', daysWithTimeIntervals)),
    removeDaysFromFormData: () => dispatch(change(formName, 'days', [])),
    removeTimeRange: () => {
      dispatch(change(formName, 'commonStartTime', null));
      dispatch(change(formName, 'commonEndTime', null));
    },
  };
};

const WorkingHoursContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkingHours);

export { WorkingHoursContainer };
