export const AUTH_ACTION_TYPES = {
  SET_AUTH_DATA: 'SET_AUTH_DATA',

  SIGN_UP: 'SIGN_UP',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  LOGOUT: 'LOGOUT',
  CLEAR_AUTH_DATA: 'CLEAR_AUTH_DATA',
};
