import { USER_ROLES } from '../constants/userRoles';
import { UrlFactory } from '../helpers/urlFactory';

export const getUserRoles = () => {
  const userData = localStorage.getItem('userData');
  const userRoles = JSON.parse(userData).roles;

  return userRoles;
};

export const isCurrentUserAdmin = () => {
  const userRoles = getUserRoles();

  return userRoles.includes(USER_ROLES.ADMIN);
};

export const getAuthorizedUserRedirectUrl = () => {
  let redirectUrl = '/';
  const userRoles = getUserRoles();
  userRoles.forEach(role => {
    switch (role) {
      case USER_ROLES.ADMIN:
        redirectUrl = UrlFactory.getBrandsUrl();
        break;
      case USER_ROLES.BRAND_MEMBER:
        redirectUrl = UrlFactory.getBrandsUrl();
        break;
      default:
        redirectUrl = '/';
    }
  });
  return redirectUrl;
};

export const isUserInRole = userRole => {
  const userRoles = getUserRoles();
  return userRoles.find(role => role === userRole);
};
