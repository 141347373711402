import './Input.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';

import { CloseIcon } from '../Icons/CloseIcon';

const InnerInput = props => {
  const {
    input,
    customClass,
    labelBefore,
    labelAfter,
    withClearing,
    type,
    meta: { touched, error, warning },
    customChange,
    multiline,
    symbolBeforeValue,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => props.clearAfterUnmount && props.input.onChange(''), []);

  const handleChange = e => {
    const target = e.target;
    const value = target.value;
    props.input.onChange(value);

    if (customChange) {
      customChange(value);
    }
  };

  const handleClear = () => {
    props.input.onChange('');

    if (customChange) {
      customChange('');
    }
  }

  return (
    <div className={classnames('input', customClass)}>
      {labelBefore && (
        <div className={classnames('input__label-before', `${customClass}__label-before`)}>
          {labelBefore}
        </div>
      )}
      <div className={classnames('input__input-container', `${customClass}__container`)}>
        {multiline ? (
          <textarea
            className={classnames('input__input', `${customClass}__input`)}
            {...input}
            type={type}
            autoComplete="new-password"
            onChange={e => handleChange(e)}
          />
        ) : (
          <React.Fragment>
            {symbolBeforeValue && (
              <div
                className={classnames(
                  'input__symbol-before-value',
                  `${customClass}__symbol-before-value`,
                )}
              >
                {symbolBeforeValue}
              </div>
            )}
            <input
              className={classnames('input__input', `${customClass}__input`)}
              {...input}
              type={type}
              autoComplete="new-password"
              onChange={e => handleChange(e)}
            />
          </React.Fragment>
        )}
        {withClearing && <CloseIcon className="input__close-icon" onClick={handleClear}/>}
      </div>
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      {labelAfter && <label className="input__label-after">{labelAfter}</label>}
    </div>
  );
};

export const Input = props => <Field name={props.name} component={InnerInput} {...props} />;

Input.defaultProps = {
  labelBefore: null,
  labelAfter: null,
  withClearing: false,
  clearAfterUnmount: false,
  multiline: false,
  symbolBeforeValue: ''
};

Input.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  withClearing: PropTypes.bool,
  customClass: PropTypes.string,
  customChange: PropTypes.func,
  clearAfterUnmount: PropTypes.bool,
  multiline: PropTypes.bool,
  symbolBeforeValue: PropTypes.string
};

export default Input;
