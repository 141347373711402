import { all, put, takeLatest } from 'redux-saga/effects';
import { notificationActions } from '../notification/notificationActions';
import { NOTIFICATION_MESSAGES } from '../notification/notificationConstants';
import { overlayActions } from '../overlay/overlayActions';
import { offerApi } from '../../../api/offerApi';
import { offerActions } from './offerActions';
import { OFFER_ACTION_TYPES } from './offerConstants';
import { brandActions } from '../brand/brandActions';

function* createDiscountSaga({ payload: { getOfferId, ...payload } }) {
  yield put(overlayActions.showOverlay());
  try {
    const response = yield offerApi.createDiscount(payload);
    const { offerId } = response.data;
    getOfferId(offerId);
    yield put(offerActions.updateCreatedOfferId(offerId));
    yield put(notificationActions.showSuccessNotification('Discount offer has been created'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('createDiscount', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* createSetSaga({ payload: { getOfferId, ...payload } }) {
  yield put(overlayActions.showOverlay());
  try {
    const response = yield offerApi.createSet(payload);
    const { offerId } = response.data;
    getOfferId(offerId);
    yield put(offerActions.updateCreatedOfferId(offerId));
    yield put(notificationActions.showSuccessNotification('Set offer has been created'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('createSet', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* createHappyHourSaga({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    yield offerApi.createHappyHour(payload);
    yield put(notificationActions.showSuccessNotification('Happy hour offer has been created'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('createHappyHour', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* updateHappyHourSaga({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    yield offerApi.updateHappyHour(payload);
    yield put(notificationActions.showSuccessNotification('Happy hour offer has been updated'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('updateHappyHour', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* updateOfferCouponInformationSaga({ payload }) {
  yield put(overlayActions.showOverlay());
  try {
    const { data, offerId } = payload;
    yield offerApi.updateOfferCouponInformation(data, offerId);
    yield put(notificationActions.showSuccessNotification('Coupon information has been added'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('update offer coupon information', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* getOfferDetailsSaga({ payload }) {
  const offerId = payload;
  try {
    const response = yield offerApi.getOfferDetails(offerId);

    if (response) {
      yield put(offerActions.getOfferDetailsSuccess(response.data));
    }
  } catch (error) {
    console.error('getOfferDetails', error);
  }
}

function* removeOfferSaga({ payload }) {
  const { offerId, brandId } = payload;
  yield put(overlayActions.showOverlay());
  try {
    yield offerApi.removeOffer(offerId);
    yield put(brandActions.getBrandOffers(brandId));
    yield put(notificationActions.showSuccessNotification('Offer has been removed'));
  } catch (error) {
    yield put(notificationActions.showErrorNotification(NOTIFICATION_MESSAGES.errorMessage));
    console.error('remove offer', error);
  } finally {
    yield put(overlayActions.hideOverlay());
  }
}

function* sagas() {
  yield all([
    takeLatest(OFFER_ACTION_TYPES.CREATE_DISCOUNT, createDiscountSaga),
    takeLatest(OFFER_ACTION_TYPES.CREATE_SET, createSetSaga),
    takeLatest(OFFER_ACTION_TYPES.CREATE_HAPPY_HOUR, createHappyHourSaga),
    takeLatest(OFFER_ACTION_TYPES.UPDATE_HAPPY_HOUR, updateHappyHourSaga),
    takeLatest(
      OFFER_ACTION_TYPES.UPDATE_OFFER_COUPON_INFORMATION,
      updateOfferCouponInformationSaga,
    ),
    takeLatest(OFFER_ACTION_TYPES.GET_OFFER_DETAILS, getOfferDetailsSaga),
    takeLatest(OFFER_ACTION_TYPES.REMOVE_OFFER, removeOfferSaga),
  ]);
}

export const offerSagas = sagas;
