import './Multiselect.scss';

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, blur } from 'redux-form';

import { CloseIcon } from '../Icons/CloseIcon';
import { ArrowIcon } from '../Icons/ArrowIcon';

class InnerMultiselect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: [],
      expanded: false,
    };
  }

  handleChange = e => {
    const target = e.target;
    const value = target.value;
    if (
      (this.state.values && this.state.values.indexOf(value) !== -1) ||
      this.state.values.length >= this.props.maxCount ||
      value === this.props.placeholder
    ) {
      return;
    }
    const newValues = this.state.values;
    newValues.push(value);
    this.setState({ values: newValues });
    this.props.input.onChange(this.state.values);
    this.props.blur(this.props.meta.form, this.props.input.name, newValues);
  };

  removeOption = value => {
    const newValues = this.state.values;
    const optionToRemove = this.state.values.indexOf(value);
    newValues.splice(optionToRemove, 1);
    this.setState({ values: newValues });
    this.props.input.onChange(this.state.values);
    this.props.blur(this.props.meta.form, this.props.input.name, this.props.isValid(newValues));
  };

  renderSelected = value => {
    const { label } = this.props.options.find(i => i.value === value);

    if (label) {
      return (
        <div key={value} className="multiselect__selected">
          {label}
          <CloseIcon className="multiselect__close-icon" onClick={() => this.removeOption(value)} />
        </div>
      );
    }
  };

  render() {
    const {
      customClass,
      labelBefore,
      labelAfter,
      placeholder,
      input,
      isValid,
      meta: { touched, error },
    } = this.props;

    const options = this.props.options || [];

    return (
      <div className={classnames('multiselect', customClass)}>
        {labelBefore && <label className={classnames('multiselect__label-before', `${customClass}__label-before`)}>{labelBefore}</label>}
        <select
          {...input}
          className={classnames('multiselect__select', `${customClass}__select`)}
          onChange={e => this.handleChange(e)}
          onBlur={() => input.onBlur(undefined, isValid ? isValid(this.state.values) : () => {})}
          value={placeholder}
        >
          {/* Here is trick for placeholder imitation for select */}
          <option disabled style={{ display: 'none' }}>
            {placeholder}
          </option>
          {options.map(i => {
            return (
              <option key={i.value} value={i.value}>
                {i.label}
              </option>
            );
          })}
        </select>
        <ArrowIcon className="multiselect__arrow-icon" />
        {touched && (error && <span>{error}</span>)}
        {this.state.values &&
          this.state.values.map(value => {
            return this.renderSelected(value);
          })}
        {labelAfter && <label className="multiselect__selector-after">{labelAfter}</label>}
      </div>
    );
  }
}

export const MultiselectField = props => (
  <Field name={props.name} component={InnerMultiselect} {...props} />
);

MultiselectField.defaultProps = {
  value: '',
  maxCount: 99999,
  labelBefore: null,
  labelAfter: null,
  placeholder: '',
};

MultiselectField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  placeholder: PropTypes.string,
  maxCount: PropTypes.number,
  customClass: PropTypes.string
};

const mapDispatchToProps = {
  blur,
};

export const Multiselect = connect(
  null,
  mapDispatchToProps,
)(MultiselectField);
