import { CUISINES_ACTION_TYPES } from './cuisinesConstants';

const defaultState = {
  cuisines: [],
};

export const cuisinesReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CUISINES_ACTION_TYPES.GET_CUISINES_SUCCESS:
      return { ...state, cuisines: payload };
    default:
      return state;
  }
};
