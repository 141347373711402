export const MENU_ACTION_TYPES = {
  GET_MENU_LIST: 'GET_MENU_LIST',
  GET_MENU_LIST_SUCCESS: 'GET_MENU_LIST_SUCCESS',
  UPDATE_SELECTED_MENU_LIST: 'UPDATE_SELECTED_MENU_LIST',
  CLEAR_SELECTED_MENU_LIST: 'CLEAR_SELECTED_MENU_LIST',
  GET_MENU_CATEGORIES: 'GET_MENU_CATEGORIES',
  GET_MENU_CATEGORIES_SUCCESS: 'GET_MENU_CATEGORIES_SUCCESS',
  UPDATE_MENU_CATEGORIES: 'UPDATE_MENU_CATEGORIES',
  UPDATE_MENU_CATEGORIES_SUCCESS: 'UPDATE_MENU_CATEGORIES_SUCCESS',
  ADD_MENU_ITEM: 'ADD_MENU_ITEM',
  UPDATE_MENU_ITEM: 'UPDATE_MENU_ITEM',
  REMOVE_MENU_ITEM: 'REMOVE_MENU_ITEM',
};

export const SUB_CATEGORIES = {
  ALL_ITEMS: 'All items',
};
