import React from 'react';

export const CalendarIcon = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 7C4 6.44772 4.44772 6 5 6H20C20.5523 6 21 6.44772 21 7V20C21 20.5523 20.5523 21 20 21H5C4.44772 21 4 20.5523 4 20V7Z"
      stroke="#919699"
      strokeWidth="2"
    />
    <path d="M4 10H21" stroke="#919699" strokeWidth="2" />
    <path d="M8 4V6" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
    <path d="M17 4V6" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
    <rect x="7" y="13" width="2" height="2" fill="#919699" />
    <rect x="11.5" y="13" width="2" height="2" fill="#919699" />
    <rect x="16" y="13" width="2" height="2" fill="#919699" />
  </svg>
);
