import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, change } from 'redux-form';

import { EditOfferPageComponent } from './EditOfferPage';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';
import { brandActions } from '../../redux/modules/brand/brandActions';
import { offerActions } from '../../redux/modules/offer/offerActions';
import { getDaysWithTimeOffsetIntervals } from '../../helpers/dayHelper';

const mapStateToProps = (state, ownProps) => {
  const {
    form,
    offer: { fetching, offerDetails },
    brand: { selectedBrandPlaces },
  } = state;
  const { match } = ownProps;
  const { brandId, offerId } = match.params;
  const selectedBrand = getBrandById(state, brandId);

  return {
    selectedBrand,
    brandId,
    offerId,
    offerDetailsFetching: fetching,
    formObj: form.editOfferForm || { values: {} },
    happyHourFormObj: form.happyHourForm || { values: {} },
    selectedBrandPlaces,
    offerDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  getBrandPlaces: id => dispatch(brandActions.getBrandPlaces(id)),
  setOfferTitle: title => dispatch(change('editOfferForm', 'title', title)),
  setOfferDescription: description => dispatch(change('editOfferForm', 'description', description)),
  setOfferImage: imageUrl => dispatch(change('editOfferForm', 'image', imageUrl)),
  setPlacesIdsToFormData: ids => dispatch(change('editOfferForm', 'placesIds', ids)),
  setMenuItemsIds: ids => dispatch(change('happyHourForm', 'menuItemsIds', ids)),
  setDiscountedPrices: discountedPrices =>
    dispatch(change('happyHourForm', 'discountedPrices', discountedPrices)),
  setDiscountedPrice: (id, discountedPrice) =>
    dispatch(change('happyHourForm', `discountedPrice${id}`, discountedPrice)),
  pushDaysToFormData: daysWithTimeIntervals =>
    dispatch(change('happyHourForm', 'daysWithTimeIntervals', daysWithTimeIntervals)),
  setStartDate: startDate => dispatch(change('happyHourForm', 'startDate', startDate)),
  setEndDate: endDate => dispatch(change('happyHourForm', 'endDate', endDate)),
  setExcludedCustomDays: days => dispatch(change('happyHourForm', 'excludedCustomDays', days)),
  setExcludedDay: (id, day) => dispatch(change('happyHourForm', `excludedDay${id}`, day)),
  getOfferDetails: id => dispatch(offerActions.getOfferDetails(id)),
  updateHappyHour: (id, formObj, happyHourFormObj) => {
    const { title, description, image, placesIds } = formObj.values;
    const {
      startDate,
      endDate,
      excludedDaysOfWeek,
      excludedCustomDays,
      discountedPrices,
      daysWithTimeIntervals,
    } = happyHourFormObj.values;
    const daysWithTimeOffsetIntervals = getDaysWithTimeOffsetIntervals(daysWithTimeIntervals);

    dispatch(
      offerActions.updateHappyHour({
        id,
        offerName: title,
        menuItems: discountedPrices,
        whenPublic: {
          startDate,
          endDate,
          excludedDates: [...excludedDaysOfWeek, ...excludedCustomDays],
        },
        offerDays: daysWithTimeOffsetIntervals,
        placesIds,
        description,
        imageUrl: image,
      }),
    );
  },
});

const form = reduxForm({
  form: 'editOfferForm',
  initialValues: {
    title: '',
  },
  onSubmit: () => {},
})(EditOfferPageComponent);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const EditOfferPage = compose(
  withRouter,
  withConnect,
)(form);
