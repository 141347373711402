import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Button } from '../../Button';
import { TimeRange } from '../TimeRange';
import { daysNames } from '../../../../helpers/dayHelper';
import './TimeRanges.scss';

const renderTimeRanges = ({ fields: days, dayIndex }) => (
  <React.Fragment>
    {days.length !== 0 && (
      <div className="time-ranges">
        <div className="time-ranges__title">{daysNames.get(dayIndex)}</div>
        {days.map((day, index) => (
          <React.Fragment key={index}>
            <div className="time-ranges__time-with-button" key={index}>
              <TimeRange startTimeName={`${day}.startTime`} endTimeName={`${day}.endTime`} />
              <Button customClass="time-ranges__remove-button" onClick={() => days.remove(index)}>
                Remove
              </Button>
            </div>
          </React.Fragment>
        ))}

        <div className="time-ranges__add" onClick={() => days.push()}>
          Add interval
        </div>
      </div>
    )}
  </React.Fragment>
);

const TimeRanges = props => {
  const { daysWithTimeIntervals } = props;
  return (
    <React.Fragment>
      {daysWithTimeIntervals.map((day, index) => (
        <FieldArray
          key={index}
          name={`daysWithTimeIntervals[${index}].timeIntervals`}
          component={renderTimeRanges}
          dayIndex={day.dayOfWeek}
        />
      ))}
    </React.Fragment>
  );
};

TimeRanges.propTypes = {
  daysWithTimeIntervals: PropTypes.array.isRequired,
};

export { TimeRanges };
