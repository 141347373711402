import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../../components/common/Search';
import './Header.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.timeout = 0;
  }

  handleSearch = searchString => {
    const { getAllBrandsBySearch } = this.props;

    if (this.timeout) clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      getAllBrandsBySearch(searchString);
    }, 1000);
  };

  render() {
    return (
      <div className="header">
        <div className="header__title">Your brands</div>
        <Search
          name="search"
          placeholder="Search brands"
          withFilter
          customChange={string => this.handleSearch(string)}
        />
      </div>
    );
  }
}

Header.propTypes = {
  getAllBrandsBySearch: PropTypes.func.isRequired,
};

export { Header };
