import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../components/common/Search';
import { Button } from '../../components/common/Button';
import { CategorySelector } from '../../components/common/CategorySelector';
import { MenuItemsList } from './MenuItemsList';
import { Spinner } from '../../components/common/Spinner';
import { UrlFactory } from '../../helpers/urlFactory';
import './MenuPage.scss';

const Menu = props => {
  const {
    selectedCategoryName,
    history,
    getMenuList,
    categories,
    subCategories,
    selectedMenuList,
    fetching,
    updateSelectedMenuList,
    setInitialCategory,
    brandId,
    selectedBrand,
    removeMenuItem,
  } = props;
  const areCategoriesEmpty = categories.length === 0;
  const areSubCategoriesEmpty = subCategories.length === 0;
  const areMenuItemsEmpty = selectedCategoryName && selectedMenuList.items.length === 0;
  const brandName = selectedBrand ? selectedBrand.name : '';

  useEffect(() => {
    getMenuList(brandId);
  }, [getMenuList, brandId]);

  return (
    <div className="menu">
      <div className="menu__title-container">
        <div className="menu__title">{brandName} menu</div>
        <Search name="search" withFilter />
      </div>
      {fetching ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="menu__categories-container">
            <div className="menu__categories-selector">
              <CategorySelector
                categories={categories}
                subCategories={subCategories}
                selectedCategoryName={selectedCategoryName}
                updateSelectedMenuList={updateSelectedMenuList}
                setInitialCategory={setInitialCategory}
              />
            </div>
            <Button
              customClass="menu__add-category-button"
              onClick={() => history.push(UrlFactory.getAddMenuCategoryUrl(brandId))}
            >
              Add new category
            </Button>
          </div>
          {areCategoriesEmpty || areSubCategoriesEmpty || areMenuItemsEmpty ? (
            <div className="menu__empty-menu-container">
              <span role="img" aria-label="sad" className="menu__empty-menu-emoji">
                😢
              </span>
              <div className="menu__empty-menu-title">Apparently, you haven’t added a menu yet</div>
              <Button
                customClass="menu__add-button"
                onClick={() => history.push(UrlFactory.getAddMenuItemUrl(brandId))}
              >
                Add menu
              </Button>
            </div>
          ) : (
            <React.Fragment>
              <MenuItemsList
                selectedCategoryName={selectedCategoryName}
                menuItems={selectedMenuList.items}
                history={history}
                brandId={brandId}
                removeMenuItem={removeMenuItem}
              />
              <div className="menu__footer">
                <Button
                  customClass="menu__footer-button"
                  onClick={() => history.push(UrlFactory.getAddMenuItemUrl(brandId))}
                >
                  Add new item
                </Button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

Menu.defaultProps = {
  selectedCategoryName: '',
};

Menu.propTypes = {
  selectedCategoryName: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  getMenuList: PropTypes.func.isRequired,
  subCategories: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  selectedMenuList: PropTypes.object.isRequired,
  fetching: PropTypes.bool.isRequired,
  updateSelectedMenuList: PropTypes.func.isRequired,
  setInitialCategory: PropTypes.func.isRequired,
  brandId: PropTypes.string.isRequired,
  selectedBrand: PropTypes.object,
  removeMenuItem: PropTypes.func.isRequired,
};

export { Menu };
