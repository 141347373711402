import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from '../Spinner';
import './PageOverlay.scss';

const InnerPageOverlay = props => {
  const { showOverlay } = props;
  return (
    <React.Fragment>
      {showOverlay ? (
        <div className="page-overlay">
          <Spinner />
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { showOverlay } = state.overlay;

  return {
    showOverlay,
  };
};

const PageOverlay = connect(
  mapStateToProps,
  null,
)(InnerPageOverlay);

export { PageOverlay };
