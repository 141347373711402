import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup } from '../CheckboxGroup';
import { Search } from '../Search';
import { CategorySelector } from '../CategorySelector';
import './MultiselectableMenu.scss';

const MultiselectableMenu = props => {
  const {
    categories,
    updateSelectedMenuList,
    selectedMenuList,
    subCategories,
    currencySymbol,
    selectedCategoryName,
    setInitialCategory,
    numberOfCards,
  } = props;
  const checkboxItems = selectedMenuList.items.map(item => {
    return {
      label: item.name,
      sublabel: `${currencySymbol} ${item.price} ${item.isVisible ? '' : ' (Hidden)'}`,
      value: item.id,
    };
  });

  return (
    <React.Fragment>
      <div className="multiselectable-menu__selector-with-search">
        <div className="multiselectable-menu__selector">
          <CategorySelector
            categories={categories}
            subCategories={subCategories}
            selectedCategoryName={selectedCategoryName}
            updateSelectedMenuList={updateSelectedMenuList}
            setInitialCategory={setInitialCategory}
            numberOfCards={numberOfCards}
          />
        </div>
        <Search placeholder="Search item" name="item-search" />
      </div>
      <CheckboxGroup options={checkboxItems} name="menuItemsIds" currencySymbol={currencySymbol} />
    </React.Fragment>
  );
};

MultiselectableMenu.propTypes = {
  categories: PropTypes.array.isRequired,
  updateSelectedMenuList: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  selectedMenuList: PropTypes.object.isRequired,
  subCategories: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  setInitialCategory: PropTypes.func.isRequired,
  numberOfCards: PropTypes.number
};

export { MultiselectableMenu };
