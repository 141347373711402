import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { brandActions } from '../../../redux/modules/brand/brandActions';
import { BrandItem } from './BrandItem';

/**
 * @param {object} state
 * @param {object} ownProps
 * @param {object} ownProps.brand
 */
const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = {
  selectBrand: brandActions.selectBrand
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const BrandItemContainer = compose(
  withRouter,
  withConnect,
)(BrandItem);
