import { CURRENCIES_ACTION_TYPES } from './currenciesConstants';

const defaultState = {
  currencies: [],
};

export const currenciesReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CURRENCIES_ACTION_TYPES.GET_CURRENCIES_SUCCESS:
      return { ...state, currencies: payload };
    default:
      return state;
  }
};
