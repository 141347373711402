import './AddOfferPageComponent.scss';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ArrowIcon } from '../../components/common/Icons/ArrowIcon';
import { Input } from '../../components/common/Input';
import { ImageUpload } from '../../components/common/ImageUpload';
import { Button } from '../../components/common/Button';
import { SetFormContainer } from './SetForm';
import { DiscountFormContainer } from './DiscountForm';
import { HappyHourFormContainer } from './HappyHourForm';
import { PlacesSelectorContainer } from '../../components/common/PlacesSelector';
import { TextArea } from '../../components/common/TextArea';

export const AddOfferPageComponent = props => {
  const {
    selectedBrand,
    brandId,
    clearSelectedMenuList,
    menuFetching,
    getBrandPlaces,
    selectedBrandPlaces,
  } = props;
  const [offerType, setOfferType] = useState('Discount');
  const brandName = selectedBrand ? selectedBrand.name : '';

  useEffect(() => {
    if (brandId) {
      getBrandPlaces(brandId);
    }
  }, [getBrandPlaces, brandId]);

  const changeOfferType = offerType => {
    setOfferType(offerType);

    if (!menuFetching) {
      clearSelectedMenuList();
    }
  };

  const renderOfferForm = () => {
    switch (offerType) {
      case 'Discount':
        return (
          <React.Fragment>
            <DiscountFormContainer brandId={brandId} />
          </React.Fragment>
        );
      case 'Set':
        return (
          <React.Fragment>
            <SetFormContainer brandId={brandId} />
          </React.Fragment>
        );
      case 'HappyHour':
        return (
          <React.Fragment>
            <HappyHourFormContainer brandId={brandId} />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <input type="range" min="0" max="100" step="1" value="50" />
          </React.Fragment>
        );
    }
  };

  if (!selectedBrand) {
    return null;
  } else
    return (
      <div className="add-offer">
        <div className="add-offer__header">Add Offer for {brandName}</div>
        <div className="add-offer__form">
          <PlacesSelectorContainer places={selectedBrandPlaces} formName="addOfferForm" />
          <div className="add-offer__basic-info-container">
            <div className="add-offer__container-header">
              <div className="add-offer__title">Basic information </div>
              <ArrowIcon />
            </div>
            <Input
              name="title"
              labelBefore="Offer title"
              customClass="add-offer__input"
              withClearing={true}
            />
            <TextArea name="description" labelBefore="Offer description" />
            <ImageUpload
              name="image"
              customClass="add-offer__photo-upload"
              description="Upload a photo of your offer in order to gain more attention."
            />
          </div>

          <div className="add-offer__offer-details-container">
            <div className="add-offer__container-header">
              <div className="add-offer__title">Offer details </div>
              <ArrowIcon />
            </div>
            <div className="add-offer__segmented-buttons-container">
              <div>Offer Type</div>
              <div className="add-offer__segmented-buttons">
                <Button
                  onClick={() => changeOfferType('Set')}
                  customClass={
                    offerType === 'Set'
                      ? 'add-offer__segmented-button-selected'
                      : 'add-offer__segmented-button'
                  }
                >
                  Set
                </Button>
                <Button
                  onClick={() => changeOfferType('HappyHour')}
                  customClass={
                    offerType === 'HappyHour'
                      ? 'add-offer__segmented-button-selected'
                      : 'add-offer__segmented-button'
                  }
                >
                  Happy Hour
                </Button>
                <Button
                  onClick={() => changeOfferType('Discount')}
                  customClass={
                    offerType === 'Discount'
                      ? 'add-offer__segmented-button-selected'
                      : 'add-offer__segmented-button'
                  }
                >
                  Discount
                </Button>
              </div>
            </div>
            <div className="add-offer__offer-container">{renderOfferForm()}</div>
          </div>
        </div>
      </div>
    );
};

AddOfferPageComponent.defaultProps = {
  title: '',
};

AddOfferPageComponent.propTypes = {
  selectedBrand: PropTypes.object,
  clearSelectedMenuList: PropTypes.func.isRequired,
  menuFetching: PropTypes.bool.isRequired,
  brandId: PropTypes.string.isRequired,
  getBrandPlaces: PropTypes.func.isRequired,
};
