import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector, arrayPush, change } from 'redux-form';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';
import { menuActions } from '../../redux/modules/menu/menuActions';
import { AddCategory } from './AddCategoryPage';

const mapStateToProps = (state, ownProps) => {
  const formSelector = formValueSelector('addCategoryForm');
  const { brandId } = ownProps.match.params;
  const selectedBrand = getBrandById(state, brandId);
  const {
    menu: { menuCategories, menuCategoriesFetching },
  } = state;

  return {
    categoryName: formSelector(state, 'categoryName'),
    categoryId: formSelector(state, 'categoryId'),
    updatedCategories: formSelector(state, 'categories'),
    selectedBrand,
    brandId,
    categories: menuCategories,
    fetching: menuCategoriesFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pushCategoryToFormData: value =>
      dispatch(arrayPush('addCategoryForm', 'categories', { name: value, subcategories: [] })),
    pushCategoriesToFormData: categories =>
      dispatch(change('addCategoryForm', 'categories', categories)),
    clearCategoryName: () => dispatch(change('addCategoryForm', 'categoryName', '')),
    getMenuCategories: brandId => dispatch(menuActions.getMenuCategories(brandId)),
    updateMenuCategories: (brandId, categories) =>
      dispatch(menuActions.updateMenuCategories(brandId, categories)),
    clearCategoryId: () => dispatch(change('addCategoryForm', 'categoryId', '0')),
  };
};

const form = reduxForm({
  form: 'addCategoryForm',
  initialValues: {
    categoryId: '0',
    categoryName: '',
  },
  onSubmit: () => {},
})(AddCategory);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const AddCategoryPage = compose(
  withRouter,
  withConnect,
)(form);

export { AddCategoryPage };
