import { all, takeLatest, put } from 'redux-saga/effects';
import { dataApi } from '../../../api/dataApi';
import { cuisinesActions } from './cuisinesActions';
import { CUISINES_ACTION_TYPES } from './cuisinesConstants';

function* getCuisines() {
  try {
    const response = yield dataApi.getCuisines();
    
    if (response) {
      yield put(cuisinesActions.getCuisinesSuccess(response.data));
    }
  } catch (err) {
    console.error('get cuisines error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(CUISINES_ACTION_TYPES.GET_CUISINES, getCuisines),
  ]);
}

export const cuisinesSagas = sagas;
