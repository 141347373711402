import { PLACE_ACTION_TYPES } from './placeConstants';

const defaultState = {
  places: [],
  fetching: true,
};

export const placeReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case PLACE_ACTION_TYPES.GET_PLACES:
      return { ...state, fetching: true };
    case PLACE_ACTION_TYPES.GET_PLACES_SUCCESS: {
      return { ...state, places: payload, fetching: false };
    }
    default:
      return state;
  }
};
