import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../../../assets/images/logo.png';
import { UrlFactory } from '../../../helpers/urlFactory';
import './Header.scss';

const Header = props => {
  const { history, logout } = props;

  return (
    <div className="global-header">
      <img
        src={Logo}
        alt=""
        onClick={() => history.push(UrlFactory.getHomeUrl())}
        className="global-header__logo"
      />
      <div className="global-header__links">
        <div className="global-header__link" onClick={() => history.push('/places-map')}>
          View on Map
        </div>
        <div
          className="global-header__link"
          onClick={() => history.push(UrlFactory.getBrandsUrl())}
        >
          My brands
        </div>
        <div className="global-header__link">Active Offers</div>
        <div className="global-header__sign-out" onClick={() => logout()}>
          Sign Out
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export { Header };
