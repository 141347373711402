import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { LoginComponent } from './LoginComponent';
import { authActions } from '../../redux/modules/auth/authActions';

const mapStateToProps = state => {
  const { form } = state;

  return {
    formObj: form.loginForm || { values: {} },
  };
};

const mapDispatchToProps = {
  login: authActions.login,
};

const form = reduxForm({
  form: 'loginForm',
  destroyOnUnmount: true,
  onSubmit: () => {},
})(LoginComponent);

export const LoginPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
