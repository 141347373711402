import React from 'react';
import PropTypes from 'prop-types';
import { Header } from './Header';
import { Switch, Redirect } from 'react-router-dom';

const Layout = props => {
  const { history, children, logout } = props;

  return (
    <React.Fragment>
      <Header history={history} logout={logout} />
      <Switch>
        {children}
        <Redirect from="/*" to="/forbidden" />
      </Switch>
    </React.Fragment>
  );
};

Layout.propTypes = {
  history: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export { Layout };
