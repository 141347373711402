import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, formValueSelector, change } from 'redux-form';
import { menuActions } from '../../redux/modules/menu/menuActions';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';
import { EditMenuItem } from './EditMenuItemPage';

const mapStateToProps = (state, ownProps) => {
  const {
    menu: { menuCategories },
    form,
  } = state;
  const {
    categoryName,
    subcategoryName,
    title,
    description,
    price,
    imageUrl,
    isVisible,
    menuItemId
  } = ownProps.history.location.state;
  const { brandId } = ownProps.match.params;
  const selectedBrand = getBrandById(state, brandId);
  const formSelector = formValueSelector('editMenuItemForm');

  return {
    categories: menuCategories,
    selectedBrand,
    brandId,
    selectedCategoryId: formSelector(state, 'selectedCategoryId'),
    selectedSubCategoryId: formSelector(state, 'selectedSubCategoryId'),
    formObj: form['editMenuItemForm'] || { values: {} },
    initialValues: {
      selectedCategoryId: '',
      isVisible,
      title,
      description,
      image: imageUrl,
      price,
    },
    categoryName,
    subcategoryName,
    menuItemId
  };
};

const mapDispatchToProps = {
  getMenuCategories: menuActions.getMenuCategories,
  updateMenuItem: menuActions.updateMenuItem,
  setCategoryId: id => change('editMenuItemForm', 'selectedCategoryId', id),
  setSubcategoryId: id => change('editMenuItemForm', 'selectedSubCategoryId', id),
};

const form = reduxForm({
  form: 'editMenuItemForm',
  onSubmit: () => {},
})(EditMenuItem);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const EditMenuItemPage = compose(
  withRouter,
  withConnect,
)(form);
