import { formatDate } from 'react-day-picker/moment';
import { getCurrencySymbol } from '../../../helpers/currencyHelper';

export const getSetOfferOverviewData = props => {
  const {
    discountedPrice,
    regularPrice,
    startDate,
    endDate,
    title,
    offerType,
    includedItems,
  } = props;

  const currencySymbol = getCurrencySymbol('USD');

  return [
    {
      title: 'Offer title',
      description: title,
    },
    {
      title: 'Offer Type',
      description: offerType,
    },
    {
      title: 'Set price',
      description: `${currencySymbol}${discountedPrice} (Regular price ${currencySymbol}${regularPrice})`,
    },
    {
      title: 'Included items',
      description: includedItems.join(', '),
    },
    {
      title: 'Offer public (exclusions applied)',
      description: `${formatDate(startDate, 'DD / MM / YYYY')} - ${formatDate(
        endDate,
        'DD / MM / YYYY',
      )}`,
    },
  ];
};

export const getDiscountOfferOverviewData = props => {
  const { discount, startDate, endDate, title, offerType } = props;

  return [
    {
      title: 'Offer title',
      description: title,
    },
    {
      title: 'Offer Type',
      description: offerType,
    },
    {
      title: 'Discount amount',
      description: `${discount}%`,
    },
    {
      title: 'Offer public (exclusions applied)',
      description: `${formatDate(startDate, 'DD / MM / YYYY')} - ${formatDate(
        endDate,
        'DD / MM / YYYY',
      )}`,
    },
  ];
};
