import { OVERLAY_ACTION_TYPES } from './overlayContants';

const defaultState = {
  showOverlay: false,
};

export const overlayReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case OVERLAY_ACTION_TYPES.SHOW_OVERLAY:
      return { ...state, showOverlay: true };
    case OVERLAY_ACTION_TYPES.HIDE_OVERLAY:
      return { ...state, showOverlay: false };
    default:
      return state;
  }
};
