import React from 'react';

export const MinusIcon = props => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.5 12.5L20.5 12.5" stroke="#919699" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
