import React from 'react';

export const AddIcon = props => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12.5 4.5V20.5" stroke="#5B6063" strokeWidth="2" strokeLinecap="round" />
    <path d="M4.5 12.5L20.5 12.5" stroke="#5B6063" strokeWidth="2" strokeLinecap="round" />
  </svg>
);
