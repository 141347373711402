import { AUTH_ACTION_TYPES } from './authConstants';

const defaultState = {
  userData: null,
  token: null,
};

export const authReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case AUTH_ACTION_TYPES.SET_AUTH_DATA:
      return { ...state, token: payload.token, userData: payload.userData };

    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      return { ...state, userData: payload };

    case AUTH_ACTION_TYPES.CLEAR_AUTH_DATA: {
      return defaultState;
    }
    default:
      return state;
  }
};
