import './SliderInput.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';

const InnerInput = props => {
  const { input, customClass, labelBefore, labelAfter, min, max, step, symbolAfterValue } = props;


  const handleChange = e => {
    const target = e.target;
    const value = target.value;
    const filteredValue = symbolAfterValue
      ? value.replace(new RegExp(` ${symbolAfterValue}`, 'g'), '')
      : value;

    props.input.onChange(filteredValue);
  };

  return (
    <div className={classnames('slider-input', customClass)}>
      {labelBefore && <label className="slider-input__label-before">{labelBefore}</label>}
      <div className="slider-input__input-container">
        <input
          className="slider-input__input"
          {...input}
          type="text"
          onChange={e => handleChange(e)}
          value={`${input.value} ${symbolAfterValue}`}
        />
        <input
          className="slider-input__input-range"
          {...input}
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={e => handleChange(e)}
        />
      </div>
      {labelAfter && <label className="slider-input__label-after">{labelAfter}</label>}
    </div>
  );
};

export const SliderInput = props => <Field name={props.name} component={InnerInput} {...props} />;

SliderInput.defaultProps = {
  value: '',
  labelBefore: null,
  labelAfter: null,
  min: '1',
  max: '100',
  step: '1',
  symbolAfterValue: '',
};

SliderInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
};

export default SliderInput;
