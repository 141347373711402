import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import './Checkbox.scss';

const InnerCheckbox = ({ index, label, sublabel, input, meta, customClass}) => {
  const { name, onChange } = input;
  const { touched, error } = meta;

  const checked = input.value;

  // TODO: Checkbox component was taken from CheckboxGroup component
  // and uses class names and styles from CheckboxGroup
  // Move checkbox styles to this component after reusing it in CheckboxGroup
  const checkbox = (
      <label
        key={`checkbox-${index}`}
        className={classnames(
            'checkbox__checkbox-container',
            customClass && `${customClass}__checkbox-container`,
        )}
      >
      <input
          type="checkbox"
          className="checkbox__checkbox"
          name={`${name}[${index}]`}
          checked={checked}
          onChange={onChange}
      />
      <div
          className={classnames('checkbox__checkmark', customClass && `${customClass}__checkmark`)}
      />
      <div
          className={classnames(
          'checkbox__label',
          customClass && `${customClass}__checkbox-label`,
          checked && 'checkbox__label-checked',
          customClass && checked && `${customClass}__checkbox-label-checked`,
          )}
      >
          {label}
      </div>
      {sublabel && <div className="checkbox__sublabel">{sublabel}</div>}
      </label>
  );

  return (
    <div className="checkbox">
      <div className={customClass && `${customClass}__checkboxes`}>{checkbox}</div>
      {touched && error && <p className="checkbox__error">{error}</p>}
    </div>
  );
};

const Checkbox = props => <Field component={InnerCheckbox} {...props} />;

InnerCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  sublabel: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  customClass: PropTypes.string,
};

export { Checkbox };
