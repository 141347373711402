import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'redux-form';
import { Selector } from '../../../components/common/Selector';
import { Input } from '../../../components/common/Input';
import { Button } from '../../../components/common/Button';
import { AddIcon } from '../../../components/common/Icons/AddIcon';
import './SubCategories.scss';

const renderSubCategories = ({ fields: subCategories }) => (
  <React.Fragment>
    {subCategories.map((subCategory, index) => (
      <div className="sub-categories__input-with-button-container" key={index}>
        <Input
          name={`${subCategory}.name`}
          labelBefore={`Subcategory name`}
          customClass="sub-categories__input"
        />
        <Button customClass="sub-categories__remove-button" onClick={() => subCategories.remove(index)}>
          Remove
        </Button>
      </div>
    ))}

    <Button customClass="sub-categories__button--full-screen" onClick={() => subCategories.push()}>
      <AddIcon className="sub-categories__button-icon" />
      {subCategories.length === 0 ? 'Add subcategory' : 'Another one'}
    </Button>
  </React.Fragment>
);

const SubCategories = props => {
  const { categories, categoryId } = props;

  return (
    <React.Fragment>
      <Selector
        name="categoryId"
        customClass="sub-categories__select-input"
        labelBefore="Select category"
        options={categories.map((category, index) => {
          return {
            value: index,
            label: category.name,
          };
        })}
      />
      <FieldArray
        name={`categories[${categoryId}].subcategories`}
        component={renderSubCategories}
      />
    </React.Fragment>
  );
};

SubCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export { SubCategories };
