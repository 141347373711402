import { OFFER_ACTION_TYPES } from './offerConstants';

export const offerActions = {
  createDiscount: data => ({
    type: OFFER_ACTION_TYPES.CREATE_DISCOUNT,
    payload: data,
  }),
  createSet: data => ({
    type: OFFER_ACTION_TYPES.CREATE_SET,
    payload: data,
  }),
  createHappyHour: data => ({
    type: OFFER_ACTION_TYPES.CREATE_HAPPY_HOUR,
    payload: data,
  }),
  updateHappyHour: data => ({
    type: OFFER_ACTION_TYPES.UPDATE_HAPPY_HOUR,
    payload: data,
  }),
  updateCreatedOfferId: data => ({
    type: OFFER_ACTION_TYPES.UPDATE_CREATED_OFFER_ID,
    payload: data,
  }),
  updateOfferCouponInformation: (data, offerId) => ({
    type: OFFER_ACTION_TYPES.UPDATE_OFFER_COUPON_INFORMATION,
    payload: {
      data,
      offerId,
    },
  }),
  getOfferDetails: data => ({
    type: OFFER_ACTION_TYPES.GET_OFFER_DETAILS,
    payload: data,
  }),
  getOfferDetailsSuccess: data => ({
    type: OFFER_ACTION_TYPES.GET_OFFER_DETAILS_SUCCESS,
    payload: data,
  }),
  removeOffer: (offerId, brandId) => ({
    type: OFFER_ACTION_TYPES.REMOVE_OFFER,
    payload: { offerId, brandId },
  }),
};
