import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MultiselectableMenu } from '../../../components/common/MultiselectableMenu';
import { AvailabilityContainer } from '../../../components/common/Availability';
import { Button } from '../../../components/common/Button';
import { Spinner } from '../../../components/common/Spinner';
import { Input } from '../../../components/common/Input';
import { getCurrencySymbol } from '../../../helpers/currencyHelper';
import { WorkingHoursContainer } from '../../../components/common/WorkingHours';
import './HappyHourForm.scss';

const HappyHourForm = props => {
  const {
    fetching,
    getMenuList,
    brandId,
    selectedMenuItemsIds,
    setDiscountedPrices,
    discountedPrices,
  } = props;
  const currencySymbol = getCurrencySymbol('USD');

  useEffect(() => {
    const updatedDiscountedPrices = selectedMenuItemsIds.map(id => {
      const existedItem = discountedPrices.find(item => item.id === id);
      if (existedItem) {
        return existedItem;
      } else {
        return {
          id: id,
          discountedPrice: null,
        };
      }
    });
    setDiscountedPrices(updatedDiscountedPrices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenuItemsIds]);

  useEffect(() => {
    getMenuList(brandId);
  }, [brandId, getMenuList]);

  if (fetching) {
    return (
      <div className="happy-hour-form__spinner">
        <Spinner />
      </div>
    );
  } else {
    const {
      formObj,
      createHappyHour,
      title,
      categories,
      selectedMenuList,
      updateSelectedMenuList,
      discountedPrices,
      setDiscountedPrices,
      selectedCategoryName,
      setInitialCategory,
      allMenuItems,
      subCategories,
      placesIds,
      description,
      imageUrl,
    } = props;

    const renderDiscountedInputs = () => {
      return selectedMenuItemsIds.map((menuItemId, index) => {
        const selectedMenuItem = allMenuItems.find(item => item.id === menuItemId);
        const { name, price } = selectedMenuItem;

        return (
          <div key={index} className="happy-hour-form__discounted-price-container">
            <div>
              {name} - {currencySymbol}
              {price}
            </div>
            <Input
              name={`discountedPrice${index}`}
              clearAfterUnmount
              customChange={value => {
                const updatedDiscountedPrices = [...discountedPrices];
                updatedDiscountedPrices.forEach(item => {
                  if (item.id === menuItemId) {
                    item.discountedPrice = value;
                  }
                });

                setDiscountedPrices(updatedDiscountedPrices);
              }}
            />
          </div>
        );
      });
    };

    return (
      <div className="happy-hour-form">
        <div className="happy-hour-form__menu-title">Select Happy Hour items</div>
        <MultiselectableMenu
          currencySymbol={currencySymbol}
          selectedMenuList={selectedMenuList}
          updateSelectedMenuList={updateSelectedMenuList}
          categories={categories}
          subCategories={subCategories}
          selectedCategoryName={selectedCategoryName}
          setInitialCategory={setInitialCategory}
          numberOfCards={4}
        />
        <div>{renderDiscountedInputs()}</div>
        <AvailabilityContainer formName="happyHourForm" />
        <WorkingHoursContainer formName="happyHourForm" />
        <Button
          onClick={() => createHappyHour(title, description, placesIds, imageUrl, formObj.values)}
          customClass="happy-hour-form__submit-button"
        >
          Save
        </Button>
      </div>
    );
  }
};

HappyHourForm.defaultProps = {
  selectedMenuItemsIds: [],
  discountedPrices: [],
  selectedCategoryName: '',
  placesIds: [],
  description: '',
  imageUrl: ''
};

HappyHourForm.propTypes = {
  fetching: PropTypes.bool.isRequired,
  getMenuList: PropTypes.func.isRequired,
  brandId: PropTypes.string.isRequired,
  selectedMenuItemsIds: PropTypes.array.isRequired,
  selectedMenuList: PropTypes.object.isRequired,
  formObj: PropTypes.object.isRequired,
  createHappyHour: PropTypes.func.isRequired,
  title: PropTypes.string,
  placesIds: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  updateSelectedMenuList: PropTypes.func.isRequired,
  setDiscountedPrices: PropTypes.func.isRequired,
  discountedPrices: PropTypes.array.isRequired,
  subCategories: PropTypes.array.isRequired,
  selectedCategoryName: PropTypes.string.isRequired,
  setInitialCategory: PropTypes.func.isRequired,
  allMenuItems: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
};

export { HappyHourForm };
