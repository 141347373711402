import { MENU_ACTION_TYPES } from './menuConstants';

export const menuActions = {
  getMenuList: brandId => ({
    type: MENU_ACTION_TYPES.GET_MENU_LIST,
    payload: brandId,
  }),
  getMenuListSuccess: data => ({
    type: MENU_ACTION_TYPES.GET_MENU_LIST_SUCCESS,
    payload: data,
  }),
  updateSelectedMenuList: data => ({
    type: MENU_ACTION_TYPES.UPDATE_SELECTED_MENU_LIST,
    payload: data,
  }),
  clearSelectedMenuList: () => ({
    type: MENU_ACTION_TYPES.CLEAR_SELECTED_MENU_LIST,
  }),
  getMenuCategories: brandId => ({
    type: MENU_ACTION_TYPES.GET_MENU_CATEGORIES,
    payload: brandId,
  }),
  getMenuCategoriesSuccess: data => ({
    type: MENU_ACTION_TYPES.GET_MENU_CATEGORIES_SUCCESS,
    payload: data,
  }),
  updateMenuCategories: (brandId, categories) => ({
    type: MENU_ACTION_TYPES.UPDATE_MENU_CATEGORIES,
    payload: { brandId, categories },
  }),
  updateMenuCategoriesSuccess: data => ({
    type: MENU_ACTION_TYPES.UPDATE_MENU_CATEGORIES_SUCCESS,
    payload: data,
  }),
  addMenuItem: data => ({
    type: MENU_ACTION_TYPES.ADD_MENU_ITEM,
    payload: data,
  }),
  updateMenuItem: data => ({
    type: MENU_ACTION_TYPES.UPDATE_MENU_ITEM,
    payload: data,
  }),
  removeMenuItem: (menuItemId, brandId) => ({
    type: MENU_ACTION_TYPES.REMOVE_MENU_ITEM,
    payload: { menuItemId, brandId },
  }),
};
