import './AddPlacePage.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { Marker, GeolocateControl } from 'react-map-gl';
import MapboxGeocoder from 'mapbox-gl-geocoder';
import classnames from 'classnames';

import { MarkerIcon } from '../../components/common/Icons/MarkerIcon';
import { SearchIcon } from '../../components/common/Icons/SearchIcon';
import { Button } from '../../components/common/Button';

const defaultLocation = {
  latitude: 37.7577,
  longitude: -122.4376,
}; /* San Francisco */

const reverseGeocodingFilters = 'place,address,poi';

export class AddPlace extends React.Component {
  constructor(props) {
    super(props);
    this._map = React.createRef();
    this.state = {
      viewport: {
        width: '100%',
        height: 365,
        zoom: 8,
        flyTo: true,
        ...defaultLocation,
      },
      markers: [],
      currentMarker: null,
    };
  }

  componentDidMount() {
    const map = this._map.current.getMap();
    this._geocoder = new MapboxGeocoder({
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      placeholder: 'Search for address',
      zoom: 8,
      flyTo: false,
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position =>
        this.changeViewport({
          ...this.state.viewport,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }

    const geocoderEl = document.getElementById('geocoder').appendChild(this._geocoder.onAdd(map));

    this._input = geocoderEl.getElementsByTagName('input')[0];

    this._input.addEventListener('change', this.onAddressInputChange);
  }

  componentWillUnmount() {
    this._input.removeEventListener('change', this.onAddressInputChange);
  }

  changeViewport = viewport => {
    this.setState({ ...this.state, viewport });
  };

  onMapClick = viewport => {
    if (
      viewport &&
      viewport.target.className !==
        'mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-active'
    ) {
      const newMarker = { latitude: viewport.lngLat[1], longitude: viewport.lngLat[0] };

      this.setState({ ...this.state, currentMarker: newMarker });

      this.reverseGeocoding(newMarker.latitude, newMarker.longitude);
    } else {
      return;
    }
  };

  onAddressInputChange = value => {
    const address = value.target.value;
    this.setMarkerAfterInputForwardGeocoding(address);
  };

  setMarkerAfterInputForwardGeocoding = address => {
    let coordinates = {};
    if (address === '') {
      return;
    }
    const request = this._geocoder.mapboxClient.geocodeForward(address, {
      types: reverseGeocodingFilters,
    });

    request.then(res => {
      if (!res.entity.features) {
        return;
      }
      coordinates = {
        latitude: res.entity.features[0].center[1],
        longitude: res.entity.features[0].center[0],
      };

      this.setState({
        ...this.state,
        viewport: { ...this.state.viewport, ...coordinates },
        currentMarker: coordinates,
      });
    });

    request.catch(function(err) {
      console.error('setMarkerAfterInputForwardGeocoding ', err);
    });
  };

  reverseGeocoding = (latitude, longitude) => {
    let place = '';

    this._geocoder.mapboxClient.geocodeReverse(
      {
        latitude,
        longitude,
      },
      (err, res) => {
        place = res.features[0].place_name;
        this._input.value = place;
      },
    );

    return place;
  };

  renderMarkers = () => {
    if (this.state.markers.length === 0 && this.state.currentMarker === null) {
      return;
    }
    const { markers } = this.state;

    return markers.map((marker, index) => {
      return (
        <Marker key={index} latitude={marker.latitude} longitude={marker.longitude}>
          <MarkerIcon className="add-place__marker-icon" />
        </Marker>
      );
    });
  };

  render() {
    const { currentMarker } = this.state;
    const { addPlace, brandId, selectedBrand } = this.props;
    const brandName = selectedBrand ? selectedBrand.name : '';

    return (
      <div className="add-place">
        <div className="add-place__header">Add place for {brandName}</div>
        <div className="add-place__form">
          <div className="add-place__geocoder-container">
            <SearchIcon className="add-place__search-icon" />
            <div id="geocoder" className={classnames('add-place__map', 'add-place__geocoder')} />
          </div>

          <div className="add-place__map-container">
            <ReactMapGL
              {...this.state.viewport}
              ref={this._map}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              className="add-place__map"
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              onViewportChange={viewport => this.changeViewport(viewport)}
              onClick={viewport => this.onMapClick(viewport)}
            >
              <GeolocateControl
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
                style={{ width: '30px', bottom: '50px', marginLeft: '710px', marginTop: '10px' }}
              />
              {this.renderMarkers()}
              {currentMarker && (
                <Marker
                  key={'current'}
                  latitude={currentMarker.latitude}
                  longitude={currentMarker.longitude}
                >
                  <MarkerIcon className="add-place__marker-icon" />
                </Marker>
              )}
            </ReactMapGL>
          </div>

          <div className="add-place__submit-button-container">
            <Button
              customClass="add-place__submit-button"
              onClick={() => addPlace(brandId, currentMarker)}
              disabled={!currentMarker}
            >
              Add place
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

AddPlace.propTypes = {
  addPlace: PropTypes.func.isRequired,
  selectedBrand: PropTypes.object,
  brandId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};
