import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import './CheckboxGroup.scss';

const InnerCheckboxGroup = ({ options, input, meta, customClass, atLeastOneOption }) => {
  const { name, onChange } = input;
  const { touched, error } = meta;
  const inputValue = input.value;
  const [disabled, setDisabled] = useState('');

  useEffect(() => {
    if (atLeastOneOption && input.value.length === 1) {
      setDisabled(input.value[0]);
    } else {
      setDisabled('');
    }
  }, [input.value.length, input.value, atLeastOneOption]);

  const checkboxes = options.map(({ label, sublabel, value }, index) => {
    const handleChange = event => {
      const arr = [...inputValue];
      if (event.target.checked) {
        arr.push(value);
      } else {
        arr.splice(arr.indexOf(value), 1);
      }
      return onChange(arr);
    };
    const checked = inputValue.includes(value);

    // TODO: Reuse Checkbox component instead of pure input
    return (
      <label
        key={`checkbox-${index}`}
        className={classnames(
          'checkbox__checkbox-container',
          customClass && `${customClass}__checkbox-container`,
        )}
      >
        <input
          type="checkbox"
          className="checkbox__checkbox"
          name={`${name}[${index}]`}
          value={value}
          checked={checked}
          onChange={handleChange}
          disabled={disabled === value}
        />
        <div
          className={classnames('checkbox__checkmark', customClass && `${customClass}__checkmark`)}
        />
        <div
          className={classnames(
            'checkbox__label',
            customClass && `${customClass}__checkbox-label`,
            checked && 'checkbox__label-checked',
            customClass && checked && `${customClass}__checkbox-label-checked`,
          )}
        >
          {label}
        </div>
        {sublabel && <div className="checkbox__sublabel">{sublabel}</div>}
      </label>
    );
  });

  return (
    <div className="checkbox">
      <div className={customClass && `${customClass}__checkboxes`}>{checkboxes}</div>
      {touched && error && <p className="checkbox__error">{error}</p>}
    </div>
  );
};

const CheckboxGroup = props => <Field component={InnerCheckboxGroup} {...props} />;

InnerCheckboxGroup.defaultProps = {
  atLeastOneOption: false
}

InnerCheckboxGroup.propTypes = {
  options: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  customClass: PropTypes.string,
  atLeastOneOption: PropTypes.bool.isRequired,
};

export { CheckboxGroup };
