import axios from 'axios';

import { API_URL } from './baseApi';

export const brandApi = {
  getBrands: async () => {
    return await axios.get(`${API_URL}/biz/brands`);
  },
  createBrand: async (name, managerId, cuisinesIds, currencyId, imageUrl) => {
    return await axios.post(`${API_URL}/adm/brands`, {
      name,
      managerId,
      cuisinesIds,
      currencyId,
      imageUrl,
    });
  },
  addPlace: async (brandId, place) => {
    return await axios.post(`${API_URL}/adm/brands/${brandId}/places`, {
      latitude: place.latitude,
      longitude: place.longitude,
    });
  },
  getAllBrands: async (searchQuery, pageNumber) => {
    return await axios.get(
      `${API_URL}/adm/brands?name=${searchQuery}&pagination.pageNumber=${pageNumber}`,
    );
  },
  getBrandsFromYelp: async searchQuery => {
    return await axios.get(`${API_URL}/adm/brands/external/search?searchTerm=${searchQuery}`);
  },
  addBrandFromYelp: async brandId => {
    return await axios.post(`${API_URL}/adm/brands/external/${brandId}/import`);
  },
  getBrandPlaces: async brandId => {
    return await axios.get(`${API_URL}/biz/brands/${brandId}/places`);
  },
  getBrandOffers: async brandId => {
    return await axios.get(`${API_URL}/biz/brands/${brandId}/offers`)
  }
};
