import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { AddBrandPageForm } from './AddBrandPage';
import { getCurrencies } from '../../redux/modules/currencies/currenciesSelectors';
import { getCuisines } from '../../redux/modules/cuisines/cuisinesSelectors';
import { cuisinesActions } from '../../redux/modules/cuisines/cuisinesActions';
import { currenciesActions } from '../../redux/modules/currencies/currenciesActions';
import { brandActions } from '../../redux/modules/brand/brandActions';

const mapStateToProps = state => {
  const { form } = state;
  const currencies = getCurrencies(state);
  const cuisines = getCuisines(state);

  const initiatCurrency = currencies[0];

  return {
    formObj: form.addBrandForm || { values: {} },
    cuisines,
    currencies,
    initialValues: {
      currency: initiatCurrency ? initiatCurrency.id : '',
      image: ''
    },
  };
};

const mapDispatchToProps = {
  createBrand: brandActions.createBrand,
  getCuisines: cuisinesActions.getCuisines,
  getCurrencies: currenciesActions.getCurrencies
};

const form = reduxForm({
  form: 'addBrandForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: () => {},
})(AddBrandPageForm);


const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const AddBrandPage = compose(
  withRouter,
  withConnect,
)(form);

export {
  AddBrandPage
}
