import React, { useEffect } from 'react';
import { ArrowIcon } from '../../../components/common/Icons/ArrowIcon';
import './PlacesSelector.scss';
import { CheckboxGroup } from '../../../components/common/CheckboxGroup';

const PlacesSelector = props => {
  const { places, setPlacesIdsToFormData } = props;

  const placesOptions = places.map(place => ({
    label: place.addressToDisplay,
    value: place.id,
  }));

  const placesIds = places.map(place => place.id);

  useEffect(() => {
    setPlacesIdsToFormData(placesIds);
  }, [placesIds, setPlacesIdsToFormData]);

  return (
    <div>
      <div className="places-selector__title-container">
        <div className="places-selector__title">Select places</div>
        <ArrowIcon />
      </div>
      <div className="places-selector__subtitle">Available places</div>
      <div className="places-selector__selector-container">
        <div className="places-selector__selector-title-container">
          <div className="places-selector__selector-title">Select at least one</div>
          <ArrowIcon />
        </div>
        <CheckboxGroup
          name="placesIds"
          options={placesOptions}
          atLeastOneOption
          customClass="places-selector"
        />
      </div>
    </div>
  );
};

export { PlacesSelector };
