import './App.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { isAuthorizedSelector } from './redux/modules/auth/authSelectors';
import { AuthorizedRoute } from './components/AuthorizedRoute';
import { NonAuthorizedRoute } from './components/NonAuthorizedRoute';
import { PrivateRoute } from './components/PrivateRoute';
import { USER_ROLES } from './constants/userRoles';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PageOverlay } from './components/common/PageOverlay';

//Pages
import { AddBrandPage } from './pages/AddBrandPage';
import { BrandListPage } from './pages/BrandListPage/BrandListPageContainer';
import { AddPlacePage } from './pages/AddPlacePage/AddPlacePageContainer';
import { SignUpPage } from './pages/SignUpPage/SignUpContainer';
import { LoginPage } from './pages/LoginPage/LoginContainer';
import { ForbiddenPage } from './pages/ForbiddenPage/ForbiddenPage';
import { getAuthorizedUserRedirectUrl } from './helpers/userRolesHelper';
import { AddOfferPage } from './pages/AddOfferPage/AddOfferPageContainer';
import { CouponInformationPage } from './pages/CouponInformationPage/CouponInformationPageContainer';
import { AddCategoryPage } from './pages/AddCategoryPage/AddCategoryPageContainer';
import { AddMenuItemPage } from './pages/AddMenuItemPage/AddMenuItemPageContainer';
import { MenuPage } from './pages/MenuPage/MenuPageContainer';
import { MainLayout } from './layouts/MainLayout/MainLayoutContainer';
import { BreadCrumbsLayout } from './layouts/BreadCrumbsLayout/BreadCrumbsLayoutContainer';
import { AddBrandFromYelpPage } from './pages/AddBrandFromYelpPage/AddBrandFromYelpPageContainer';
import { OfferListPage } from './pages/OfferListPage/OfferListPageContainer';
import { EditMenuItemPage } from './pages/EditMenuItemPage/EditMenuItemPageContainer';
import { EditOfferPage } from './pages/EditOfferPage/EditOfferPageContainer';
import { PlacesMapPage } from './pages/PlacesMapPage/PlacesMapPageContainer';

function App(props) {
  const { isAuthorized } = props;
  const redirectTo = isAuthorized ? getAuthorizedUserRedirectUrl() : '/login';

  return (
    <>
      <Switch>
        <NonAuthorizedRoute
          exact
          path={'/sign-up'}
          component={props => <SignUpPage {...props} />}
          isAuthorized={isAuthorized}
        />
        <NonAuthorizedRoute
          exact
          path={'/login'}
          component={props => <LoginPage {...props} />}
          isAuthorized={isAuthorized}
        />
        <Redirect exact from="/" to={redirectTo} />
        <Route exact path={'/forbidden'} component={props => <ForbiddenPage {...props} />} />
        <MainLayout>
          <AuthorizedRoute
            exact
            path={'/brands'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <BrandListPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/places-map'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <PlacesMapPage />
                )}
                {...props}
                allowedRoles={[USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/add'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <AddBrandPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/add/yelp'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <AddBrandFromYelpPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/offers'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <OfferListPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/offers/:offerId/edit'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <EditOfferPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/offers/add'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <AddOfferPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/places/add'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <AddPlacePage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/offers/:offerId/coupon-info'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <CouponInformationPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/menu/categories'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <AddCategoryPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/menu/add'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <AddMenuItemPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/menu/edit'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <EditMenuItemPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
          <AuthorizedRoute
            exact
            path={'/brands/:brandId/menu'}
            component={props => (
              <PrivateRoute
                component={() => (
                  <BreadCrumbsLayout>
                    <MenuPage />
                  </BreadCrumbsLayout>
                )}
                {...props}
                allowedRoles={[USER_ROLES.BRAND_MEMBER, USER_ROLES.ADMIN]}
              />
            )}
            isAuthorized={isAuthorized}
          />
        </MainLayout>
        <Redirect from="/*" to="/forbidden" />
      </Switch>
      <ToastContainer />
      <PageOverlay />
    </>
  );
}

App.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isAuthorized: isAuthorizedSelector(state),
});

export default connect(mapStateToProps)(App);
