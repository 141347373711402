import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup } from '../CheckboxGroup/CheckboxGroup';
import { TimeRanges } from './TimeRanges';
import { TimeRange } from './TimeRange';
import { Button } from '../Button';
import { mergeArrays } from '../../../helpers/arrayHelper';
import './WorkingHours.scss';

const WorkingHours = props => {
  const {
    days,
    commonStartTime,
    commonEndTime,
    pushDaysToFormData,
    daysWithTimeIntervals,
    removeDaysFromFormData,
    removeTimeRange,
  } = props;
  const daysOptions = [
    { label: 'Mon', value: 1 },
    { label: 'Tue', value: 2 },
    { label: 'Wen', value: 3 },
    { label: 'Thu', value: 4 },
    { label: 'Fri', value: 5 },
    { label: 'Sat', value: 6 },
    { label: 'Sun', value: 0 },
  ];

  const getUpdatedDaysWithTimeIntervals = () => {
    const newDaysWithTimeIntervals = days.map(day => {
      return {
        dayOfWeek: day,
        timeIntervals: [{ startTime: commonStartTime, endTime: commonEndTime }],
      };
    });

    const updatedDaysWithTimeIntervals = mergeArrays(
      daysWithTimeIntervals,
      newDaysWithTimeIntervals,
      'dayOfWeek',
    );

    return updatedDaysWithTimeIntervals;
  };

  return (
    <div className="working-hours">
      <div className="working-hours__title">Working hours</div>
      <CheckboxGroup name="days" options={daysOptions} customClass="working-hours" />
      <div className="working-hours__range-with-button">
        <TimeRange startTimeName="commonStartTime" endTimeName="commonEndTime" />
        <Button
          customClass="working-hours__save-button"
          onClick={() => {
            const updatedDaysWithTimeIntervals = getUpdatedDaysWithTimeIntervals();
            pushDaysToFormData(updatedDaysWithTimeIntervals);
            removeDaysFromFormData();
            removeTimeRange();
          }}
        >
          Save
        </Button>
      </div>
      <TimeRanges daysWithTimeIntervals={daysWithTimeIntervals} />
    </div>
  );
};

WorkingHours.defaultProps = {
  days: [],
  daysWithTimeIntervals: [],
};

WorkingHours.propTypes = {
  commonStartTime: PropTypes.object,
  commonEndTime: PropTypes.object,
  pushDaysToFormData: PropTypes.func.isRequired,
  daysWithTimeIntervals: PropTypes.array.isRequired,
  removeDaysFromFormData: PropTypes.func.isRequired,
  removeTimeRange: PropTypes.func.isRequired,
};

export { WorkingHours };
