import './BrandList.scss';

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrandItemContainer } from './BrandItem/BrandItemContainer';
import { AllBrandsPage } from '../AllBrandsPage/AllBrandsPageContainer';
import { isCurrentUserAdmin } from '../../helpers/userRolesHelper';

const BrandList = props => {
  const { brands, getBrands, getAllBrands, clearAllBrands } = props;
  const isAdmin = isCurrentUserAdmin();

  useEffect(() => {
    clearAllBrands();

    if (isAdmin) {
      getAllBrands(1);
    } else {
      getBrands();
    }
  }, [isAdmin, getBrands, getAllBrands, clearAllBrands]);

  if (isAdmin) {
    return <AllBrandsPage />;
  } else {
    return (
      <div className="brand-list">
        <div className="brand-list__header">
          <div className="brand-list__title">Your brands</div>
        </div>
        <div className="brand-list__brands-container">
          {brands.map((brand, index) => {
            return <BrandItemContainer key={index} brand={brand} />;
          })}
        </div>
      </div>
    );
  }
};

BrandList.propTypes = {
  brands: PropTypes.array.isRequired,
  getBrands: PropTypes.func.isRequired,
  getAllBrands: PropTypes.func.isRequired,
  clearAllBrands: PropTypes.func.isRequired
};

export { BrandList };
