import './DatePicker.scss';

import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { CalendarIcon } from '../../common/Icons/CalendarIcon';
import 'react-day-picker/lib/style.css';

const InnerDatePicker = props => {
  const {
    input,
    customClass,
    labelBefore,
    labelAfter,
    meta: { touched, error, warning },
    customChange
  } = props;

  const handleChange = e => {
    const value = e;
    props.input.onChange(value);
    customChange(value)
  };

  return (
    <div className={classnames('date-picker', customClass)}>
      {labelBefore && <label className="date-picker__label-before">{labelBefore}</label>}
      <div className="date-picker__input-container">
        <DayPickerInput
          className="date-picker__date-picker-input"
          {...input}
          format="L"
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={`${formatDate(new Date())}`}
          onDayChange={day => handleChange(day)}
        />
        <CalendarIcon className="date-picker__calendar-icon" />
      </div>
      {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      {labelAfter && <label className="date-picker__label-after">{labelAfter}</label>}
    </div>
  );
};

export const DatePicker = props => (
  <Field name={props.name} component={InnerDatePicker} {...props} />
);

DatePicker.defaultProps = {
  value: '',
  labelBefore: null,
  labelAfter: null,
  customChange: () => {}
};

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
  withClearing: PropTypes.bool,
  customChange: PropTypes.func
};

export default DatePicker;
