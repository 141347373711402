import { CUISINES_ACTION_TYPES } from './cuisinesConstants';

export const cuisinesActions = {
  getCuisines: () => ({
    type: CUISINES_ACTION_TYPES.GET_CUISINES,
  }),
  getCuisinesSuccess: data => ({
    type: CUISINES_ACTION_TYPES.GET_CUISINES_SUCCESS,
    payload: data,
  }),
};
