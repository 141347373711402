import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { AllBrands } from './AllBrandsPage';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const AllBrandsPage = compose(
  withRouter,
  withConnect,
)(AllBrands);
