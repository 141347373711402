import React from 'react';
import PropTypes from 'prop-types';
import ItemsCarousel from 'react-items-carousel';
import classnames from 'classnames';
import { ArrowIcon } from '../../../components/common/Icons/ArrowIcon';
import './Carousel.scss';

const Carousel = props => {
  const { options, activeItemIndex, setActiveItemIndex, numberOfCards, customChange } = props;
  const items = options.map((item, index) => (
    <div
      key={index}
      className={classnames('carousel__item', activeItemIndex === index && 'carousel__active-item')}
      onClick={() => {
        setActiveItemIndex(index);
        customChange(item);
      }}
    >
      {item}
    </div>
  ));

  return (
    <ItemsCarousel
      freeScrolling={false}
      requestToChangeActive={setActiveItemIndex}
      activeItemIndex={activeItemIndex}
      activePosition={'center'}
      chevronWidth={24}
      rightChevron={<ArrowIcon className="carousel__right-icon" />}
      leftChevron={<ArrowIcon className="carousel__left-icon" />}
      numberOfCards={numberOfCards}
    >
      {items}
    </ItemsCarousel>
  );
};

Carousel.defaultProps = {
  numberOfCards: 6,
  customChange: () => {},
};

Carousel.propTypes = {
  options: PropTypes.array.isRequired,
  numberOfCards: PropTypes.number.isRequired,
  activeItemIndex: PropTypes.number,
  setActiveItemIndex: PropTypes.func.isRequired,
  customChange: PropTypes.func.isRequired,
};

export { Carousel };
