export const UrlFactory = {
  getAddPlaceUrl: brandId => `/brands/${brandId}/places/add`,
  getAddOfferUrl: brandId => `/brands/${brandId}/offers/add`,
  getEditOfferUrl: (brandId, offerId) => `/brands/${brandId}/offers/${offerId}/edit`,
  getMenuUrl: brandId => `/brands/${brandId}/menu`,
  getAddMenuCategoryUrl: brandId => `/brands/${brandId}/menu/categories`,
  getAddMenuItemUrl: brandId => `/brands/${brandId}/menu/add`,
  getEditMenuItemUrl: brandId => `/brands/${brandId}/menu/edit`,
  getAddBrandUrl: () => '/brands/add',
  getBrandsUrl: () => '/brands',
  getAddCouponInformationUrl: (brandId, offerId) =>
    `/brands/${brandId}/offers/${offerId}/coupon-info`,
  getHomeUrl: () => '/',
  getBrandInfoUrl: () => '/brands/brand-info',
  getAddBrandFromYelpUrl: () => '/brands/add/yelp',
  getBrandOffersUrl: brandId => `/brands/${brandId}/offers`,
};
