import './LoginComponent.scss';

import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../components/common/Input';
import { Button } from '../../components/common/Button';
import { required } from '../../helpers/formValidation';

export const LoginComponent = props => {
  const { formObj, handleSubmit, login, invalid, submitting } = props;

  return (
    <div className="login">
      <form className="login__form" onSubmit={handleSubmit}>
        <div className="login__header">Login</div>

        <Input customClass="login__input" validate={required} name="email" labelBefore="Email" />

        <Input
          type="password"
          customClass="login__input"
          validate={required}
          name="password"
          labelBefore="Password"
        />

        <Button
          customClass="login__submit-button"
          disabled={invalid || submitting}
          onClick={() => {
            login(formObj.values);
          }}
        >
          Login
        </Button>
      </form>
    </div>
  );
};

LoginComponent.propTypes = {
  formObj: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};
