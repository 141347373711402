import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { Availability } from './Availability';

const mapStateToProps = (state, ownProps) => {
  const { form } = state;
  const { formName } = ownProps;
  const formSelector = formValueSelector(formName);

  return {
    formObj: form[formName] || { values: {} },
    startDate: formSelector(state, 'startDate'),
    endDate: formSelector(state, 'endDate'),
    excludedDays: formSelector(state, 'excludedDays'),
    excludedCustomDays: formSelector(state, 'excludedCustomDays'),
  };
};

const mapDispatchToProps = dispatch => ({
  clearDates: formName => {
    dispatch(change(formName, 'startDate', new Date()));
    dispatch(change(formName, 'endDate', new Date()));
  },
  setExcludedDaysOfWeek: (days, formName) => dispatch(change(formName, 'excludedDaysOfWeek', days)),
});

export const AvailabilityContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Availability);
