import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { OfferList } from './OfferListPage';
import { brandActions } from '../../redux/modules/brand/brandActions';
import { offerActions } from '../../redux/modules/offer/offerActions';

const mapStateToProps = (state, ownProps) => {
  const {
    brandOffers,
    fetchingBrandOffers,
    selectedBrand: { name },
  } = state.brand;
  const { brandId } = ownProps.match.params;
  return {
    brandId,
    brandOffers,
    fetching: fetchingBrandOffers,
    brandName: name,
  };
};

const mapDispatchToProps = {
  getBrandOffers: brandActions.getBrandOffers,
  removeOffer: offerActions.removeOffer,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const OfferListPage = compose(
  withRouter,
  withConnect,
)(OfferList);
