import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../../../components/common/Input';
import { ArrowIcon } from '../../../components/common/Icons/ArrowIcon';
import { Button } from '../../../components/common/Button';
import './CustomCategories.scss';

const CustomCategories = props => {
  const {
    pushCategoryToFormData,
    categoryName,
    clearCategoryName,
  } = props;

  return (
    <React.Fragment>
      <div className="custom-categories__title-container">
        <div className="custom-categories__title">Custom categories</div>
        <ArrowIcon />
      </div>
      <div className="custom-categories__input-with-button-container">
        <Input name="categoryName" customClass="custom-categories__input" />
        <Button
          disabled={!categoryName}
          customClass="custom-categories__save-button"
          onClick={() => {
            pushCategoryToFormData(categoryName);
            clearCategoryName();
          }}
        >
          Add
        </Button>
      </div>
    </React.Fragment>
  );
};

CustomCategories.propTypes = {
  pushCategoryToFormData: PropTypes.func.isRequired,
  categoryName: PropTypes.string.isRequired,
  clearCategoryName: PropTypes.func.isRequired,
};

export { CustomCategories };
