import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { brandActions } from '../../../redux/modules/brand/brandActions';
import { Header } from './Header';

const mapStateToProps = state => {
  return {
    initialValues: {
      search: '',
    },
  };
};

const mapDispatchToProps = {
  getAllBrandsBySearch: brandActions.getAllBrandsBySearch,
}

const form = reduxForm({
  form: 'adminHeaderForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: () => {},
})(Header);

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
