import { all, fork } from 'redux-saga/effects';

import { cuisinesSagas } from './modules/cuisines/cuisinesSagas';
import { currenciesSagas } from './modules/currencies/currenciesSagas';
import { brandSagas } from './modules/brand/brandSagas';
import { historySagas } from './modules/history/historySagas';
import { authSagas } from './modules/auth/authSagas';
import { offerSagas } from './modules/offer/offerSagas';
import { menuSagas } from './modules/menu/menuSagas';
import { placeSagas } from './modules/places/placeSagas';

export const rootSaga = function* rootSaga() {
  yield all([
    fork(cuisinesSagas),
    fork(currenciesSagas),
    fork(brandSagas),
    fork(historySagas),
    fork(authSagas),
    fork(offerSagas),
    fork(menuSagas),
    fork(placeSagas),
  ]);
};
