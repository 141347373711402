import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';

import { AddOfferPageComponent } from './AddOfferPageComponent';
import { getBrandById } from '../../redux/modules/brand/brandSelectors';
import { menuActions } from '../../redux/modules/menu/menuActions';
import { brandActions } from '../../redux/modules/brand/brandActions';

const mapStateToProps = (state, ownProps) => {
  const {
    form,
    menu: { fetching },
    brand: { selectedBrandPlaces }
  } = state;
  const { match } = ownProps;
  const { brandId } = match.params;
  const selectedBrand = getBrandById(state, brandId);

  return {
    selectedBrand,
    brandId,
    menuFetching: fetching,
    formObj: form.addOfferForm || { values: {} },
    selectedBrandPlaces
  };
};

const mapDispatchToProps = {
  clearSelectedMenuList: menuActions.clearSelectedMenuList,
  getBrandPlaces: brandActions.getBrandPlaces,
};

const form = reduxForm({
  form: 'addOfferForm',
  initialValues: {
    title: '',
  },
  onSubmit: () => {},
})(AddOfferPageComponent);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export const AddOfferPage = compose(
  withRouter,
  withConnect,
)(form);
