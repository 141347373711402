import { BRAND_ACTION_TYPES } from './brandConstants';

const defaultState = {
  brands: [],
  brandsFromYelp: [],
  brandOffers: [],
  selectedBrand: {},
  selectedBrandPlaces: [],
  isLoadingCompleted: false,
  fetching: true,
  fetchingBrandsFromYelp: false,
  fetchingBrandOffers: false,
};

export const brandReducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case BRAND_ACTION_TYPES.GET_BRANDS_SUCCESS:
      return { ...state, brands: payload };
    case BRAND_ACTION_TYPES.SELECT_BRAND:
      return { ...state, selectedBrand: payload };
    case BRAND_ACTION_TYPES.CLEAR_ALL_BRANDS:
      return { ...state, brands: [] };
    case BRAND_ACTION_TYPES.GET_ALL_BRANDS:
      return { ...state, fetching: true };
    case BRAND_ACTION_TYPES.GET_ALL_BRANDS_SUCCESS:
      return handleGetBrandList(state, payload);
    case BRAND_ACTION_TYPES.GET_ALL_BRANDS_BY_SEARCH:
      return { ...state, fetching: true };
    case BRAND_ACTION_TYPES.GET_ALL_BRANDS_BY_SEARCH_SUCCESS:
      return { ...state, brands: payload, fetching: false, isLoadingCompleted: true };
    case BRAND_ACTION_TYPES.UPDATE_LOADING_STATUS:
      return { ...state, isLoadingCompleted: false };
    case BRAND_ACTION_TYPES.GET_BRANDS_FROM_YELP:
      return { ...state, fetchingBrandsFromYelp: true };
    case BRAND_ACTION_TYPES.GET_BRANDS_FROM_YELP_SUCCESS:
      return { ...state, brandsFromYelp: payload, fetchingBrandsFromYelp: false };
    case BRAND_ACTION_TYPES.GET_BRAND_PLACES_SUCCESS: {
      return { ...state, selectedBrandPlaces: payload };
    }
    case BRAND_ACTION_TYPES.GET_BRAND_OFFERS: {
      return { ...state, fetchingBrandOffers: true };
    }
    case BRAND_ACTION_TYPES.GET_BRAND_OFFERS_SUCCESS: {
      return { ...state, brandOffers: payload, fetchingBrandOffers: false };
    }
    default:
      return state;
  }
};

const handleGetBrandList = (state, payload) => {
  if (payload.length !== 0) {
    return {
      ...state,
      brands: [...state.brands, ...payload],
      fetching: false,
    };
  } else {
    return {
      ...state,
      brands: [...state.brands, ...payload],
      fetching: false,
      isLoadingCompleted: true,
    };
  }
};
