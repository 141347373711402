import './TextArea.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';

const InnerTextArea = props => {
  const { customClass, labelBefore, labelAfter, input } = props;

  const handleChange = e => {
    const target = e.target;
    const value = target.value;
    props.input.onChange(value);
  };

  return (
    <div className={classnames('text-area', customClass)}>
      {labelBefore && <label className="text-area__label-before">{labelBefore}</label>}
      <textarea {...input} className="text-area__input" onChange={e => handleChange(e)} />
      {labelAfter && <label className="text-area__label-after">{labelAfter}</label>}
    </div>
  );
};

export const TextArea = props => <Field name={props.name} component={InnerTextArea} {...props} />;

TextArea.defaultProps = {
  value: '',
  labelBefore: null,
  labelAfter: null,
};

TextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelBefore: PropTypes.string,
  labelAfter: PropTypes.string,
};

export default TextArea;
