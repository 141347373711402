import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { HeaderContainer } from './Header';
import { Spinner } from '../../components/common/Spinner';
import { OFFER_TYPES } from '../../constants/offerTypes';
import moment from 'moment';
import './OfferList.scss';
import { UrlFactory } from '../../helpers/urlFactory';
import { Modal } from '../../components/common/Modal';
import { Button } from '../../components/common/Button';

const OfferList = props => {
  const { brandId, brandOffers, getBrandOffers, fetching, brandName, history, removeOffer } = props;

  useEffect(() => {
    getBrandOffers(brandId);
  }, [brandId, getBrandOffers]);

  const [selectedOfferIndex, setSelectedOfferIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [removeOfferId, setRemoveOfferId] = useState(null);

  const renderOfferList = () => {
    return brandOffers.map((offer, index) => {
      return (
        <div
          className="offer-list__item"
          key={index}
          onMouseEnter={() => setSelectedOfferIndex(index)}
          onMouseLeave={() => setSelectedOfferIndex(null)}
        >
          <div className="offer-list__item-img-with-title">
            <img src={offer.imageUrl} className="offer-list__item-img" alt="" />
            <div className="offer-list__item-title-container">
              <div className="offer-list__item-title">{offer.name}</div>
              <div className="offer-list__item-subtitle">
                {offer.type === OFFER_TYPES.HAPPY_HOUR
                  ? 'Happy Hour'
                  : `${offer.couponsLeft} coupons left`}{' '}
                · Active until {moment(offer.endDate).format('MMMM D, YYYY')}
              </div>
            </div>
          </div>
          {index === selectedOfferIndex && (
            <div className="offer-list__action-buttons">
              <div
                className="offer-list__action-button"
                onClick={() => {
                  setRemoveOfferId(offer.id);
                  setIsModalOpen(true);
                }}
              >
                Delete
              </div>
              <div
                className="offer-list__action-button"
                onClick={() => history.push(UrlFactory.getEditOfferUrl(brandId, offer.id))}
              >
                Edit
              </div>
              <div className="offer-list__action-button">View info</div>
              <div className="offer-list__action-button">View statistics</div>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="offer-list">
      <HeaderContainer />
      <div className="offer-list__brand-name">{brandName}</div>
      <div className="offer-list__list">
        {fetching ? (
          <div className="offer-list__loader">
            <Spinner />
          </div>
        ) : (
          renderOfferList()
        )}
      </div>
      <Modal title="Offer delete" isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        <div className="offer-list__remove-offer-modal">
          <div>Are you sure you want to delete this offer?</div>
          <div className="offer-list__remove-offer-modal-buttons">
            <Button
              customClass="offer-list__remove-offer-modal-remove-button"
              onClick={() => {
                removeOffer(removeOfferId, brandId);
                setIsModalOpen(false);
              }}
            >
              Delete
            </Button>
            <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

OfferList.propTypes = {
  brandId: PropTypes.string,
  brandOffers: PropTypes.array.isRequired,
  getBrandOffers: PropTypes.func.isRequired,
  fetching: PropTypes.bool.isRequired,
  brandName: PropTypes.string,
  removeOffer: PropTypes.func.isRequired,
};

export { OfferList };
