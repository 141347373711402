import moment from 'moment';

export const daysNames = new Map([
  [0, 'Sunday'],
  [1, 'Monday'],
  [2, 'Tuesday'],
  [3, 'Wednesday'],
  [4, 'Thursday'],
  [5, 'Friday'],
  [6, 'Saturday'],
]);

export const formatToUTC = date => {
  const utcMoment = moment.utc(date);
  const utcDate = new Date(utcMoment.format());
  return utcDate;
};

export const getOffsetFromDayBeginning = time => {
  const startDayTime = moment().startOf('day');
  const diff = time.diff(startDayTime);
  const diffDuration = moment.duration(diff);
  const offset = Math.floor(diffDuration.asMinutes());

  return offset;
};

export const getTimeFromOffset = offset => {
  const hours = Math.floor(offset / 60);
  const minutes = offset - hours * 60;
  const stubDate = new Date();
  stubDate.setHours(hours);
  stubDate.setMinutes(minutes);
  return moment(stubDate);
};

export const getDaysWithTimeIntervals = days => {
  return days.map(day => {
    return {
      ...day,
      timeIntervals: day.timeIntervals.map(timeInterval => {
        return {
          startTime: getTimeFromOffset(timeInterval.startTimeOffset),
          endTime: getTimeFromOffset(timeInterval.endTimeOffset),
        };
      }),
    };
  });
};

export const getDaysWithTimeOffsetIntervals = days => {
  const daysWithIntervals = days.filter(day => day.timeIntervals.length !== 0);
  return daysWithIntervals.map(day => {
    return {
      ...day,
      timeIntervals: day.timeIntervals.map(timeInterval => {
        return {
          startTimeOffset: getOffsetFromDayBeginning(timeInterval.startTime),
          endTimeOffset: getOffsetFromDayBeginning(timeInterval.endTime),
        };
      }),
    };
  });
};
