import { AUTH_ACTION_TYPES } from './authConstants';

export const authActions = {
  logout: () => ({
    type: AUTH_ACTION_TYPES.LOGOUT,
  }),
  setAuthData: (token, userData) => ({
    type: AUTH_ACTION_TYPES.SET_AUTH_DATA,
    payload: { token, userData },
  }),
  login: data => ({
    type: AUTH_ACTION_TYPES.LOGIN,
    payload: data,
  }),
  authSuccess: data => ({
    type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
    payload: data,
  }),
  signUp: data => ({
    type: AUTH_ACTION_TYPES.SIGN_UP,
    payload: data,
  }),
  clearAuthData: () => ({
    type: AUTH_ACTION_TYPES.CLEAR_AUTH_DATA,
  }),
};
