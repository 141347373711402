import { all, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

/* eslint require-yield: 0 */
export function* onLocationChangeSaga(action) {
  try {
  } catch (err) {
    console.error(err);
  }
}

function* sagas() {
  yield all([takeLatest(LOCATION_CHANGE, onLocationChangeSaga)]);
}

export const historySagas = sagas;
