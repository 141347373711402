export const OFFER_ACTION_TYPES = {
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  CREATE_SET: 'CREATE_SET',
  CREATE_HAPPY_HOUR: 'CREATE_HAPPY_HOUR',
  UPDATE_HAPPY_HOUR: 'UPDATE_HAPPY_HOUR',
  UPDATE_CREATED_OFFER_ID: 'UPDATE_CREATED_OFFER_ID',
  UPDATE_OFFER_COUPON_INFORMATION: 'UPDATE_OFFER_COUPON_INFORMATION',
  GET_OFFER_DETAILS: 'GET_OFFER_DETAILS',
  GET_OFFER_DETAILS_SUCCESS: 'GET_OFFER_DETAILS_SUCCESS',
  REMOVE_OFFER: 'REMOVE_OFFER'
};
