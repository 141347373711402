import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';

export function arraysEqual(_arr1, _arr2) {
  if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) return false;

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export const findOne = (_arr1, _arr2) => {
  return _arr1.some(item => {
    return _arr2.indexOf(item) >= 0;
  });
};

export const mergeArrays = (_arr1, _arr2, mergeKey) => {
  return Object.values(
    mergeWith(keyBy(_arr1, mergeKey), keyBy(_arr2, mergeKey), (objValue, srcValue) => {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    }),
  );
};
