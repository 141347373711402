import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { CouponInformation } from './CouponInformationPage';
import { offerActions } from '../../redux/modules/offer/offerActions';

const mapStateToProps = (state, ownProps) => {
  const { form } = state;
  const { offerId } = ownProps.match.params;

  return {
    formObj: form.couponInformationForm || { values: {} },
    offerId,
  };
};

const mapDispatchToProps = {
  updateOfferCouponInformation: offerActions.updateOfferCouponInformation,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const form = reduxForm({
  form: 'couponInformationForm',
  initialValues: {
    excludedDaysOfWeek: [],
    excludedCustomDays: [],
  },
  onSubmit: () => {},
})(CouponInformation);

export const CouponInformationPage = compose(
  withRouter,
  withConnect,
)(form);
