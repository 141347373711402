import { CURRENCIES_ACTION_TYPES } from './currenciesConstants';

export const currenciesActions = {
  getCurrencies: () => ({
    type: CURRENCIES_ACTION_TYPES.GET_CURRENCIES,
  }),
  getCurrenciesSuccess: data => ({
    type: CURRENCIES_ACTION_TYPES.GET_CURRENCIES_SUCCESS,
    payload: data,
  }),
};
