import { connect } from 'react-redux';
import { PlacesMap } from './PlacesMapPage';
import { placeActions } from '../../redux/modules/places/placeActions';

const mapStateToProps = state => {
  const { fetching, places } = state.place;
  return {
    fetching,
    places,
  };
};

const mapDispatchToProps = {
  getPlaces: placeActions.getPlaces,
};

export const PlacesMapPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlacesMap);
