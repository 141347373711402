import axios from 'axios';

import { API_URL } from './baseApi';

export const authApi = {
  signUp: async (email, password) => {
    const response = await axios.post(`${API_URL}/biz/account/register`, {
      email,
      password,
    });

    if (response.status === 200) {
      const token = response.data.accessToken;
      const userData = response.data.currentUser;

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem('authToken', token);
      localStorage.setItem('userData', JSON.stringify(userData));
    }

    return response;
  },
  login: async (email, password) => {
    const response = await axios.post(`${API_URL}/biz/account/login`, {
      email,
      password,
    });

    if (response.status === 200) {
      const token = response.data.accessToken;
      const userData = response.data.currentUser;

      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      localStorage.setItem('authToken', token);
      localStorage.setItem('userData', JSON.stringify(userData));
    }

    return response;
  },
};

export const initAuthorization = () => {
  const token = localStorage.getItem('authToken');

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  },
);

initAuthorization();
