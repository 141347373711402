import { createSelector } from 'reselect';

export const getBrands = createSelector(
  [state => state.brand],
  brand => brand.brands,
);

export const getBrandById = createSelector(
  [state => state.brand, (state, brandId) => brandId],
  (brand, brandId) => {
    const selectedBrand = brand.brands.find(i => i.id === brandId);
    return selectedBrand;
  },
);
